import React, { useState } from "react"
import {
  AsyncSingleSelectionField,
  AsyncSingleSelect,
  FieldType,
} from "../Fields"
import { makeApi } from "applications/FacilitySelectForm/api"
import { patch } from "services/api"
import { FacilityOption } from "sharedTypes"
import { useCsrInboxState } from "applications/SupplierOrganizationInboxDocument/csrInboxStateContext"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { useErrorContext } from "../../../error-context"
import { useEffectThatWontRunOnMount } from "../../../../../hooks/useEffectThatWontRunOnMount"

export type InboxStateClinicalFacility = {
  facility?: {
    id: string
    name: string
  }
  extractedName?: string
}

type SubsetOfClinicalFacility = Pick<FacilityOption, "id" | "name">
export type FetchFacilities = (
  searchTerm: string
) => Promise<SubsetOfClinicalFacility[]>

type UpdateFacilityInformation = (
  updatedData: Partial<InboxStateClinicalFacility>
) => Promise<void>

const updateFacilityInformation = ({
  supplierOrganizationId,
  csrInboxStateId,
}: {
  supplierOrganizationId: string
  csrInboxStateId: string
}): UpdateFacilityInformation => async (
  updatedData: Partial<InboxStateClinicalFacility>
): Promise<void> => {
  const pathToCsrInboxState = `/u/r/${supplierOrganizationId}/inbox_documents/${csrInboxStateId}`
  const requestBody = {
    facility_id: updatedData.facility?.id || null,
  }
  await patch(`${pathToCsrInboxState}/update_clinical_facility`, requestBody)
}

export const FacilityData = ({}: {}) => {
  const queryClient = useQueryClient()
  const csrInboxState = useCsrInboxState()
  const { displayErrors } = useErrorContext()
  const infinitySoThatThisWillNotRefetchUnlessSomeoneInvalidatesTheCacheManually = Infinity
  const { data } = useQuery<InboxStateClinicalFacility>({
    queryKey: ["csrInboxState", "facility"],
    queryFn: () => ({}),
    staleTime: infinitySoThatThisWillNotRefetchUnlessSomeoneInvalidatesTheCacheManually,
  })

  const [displayFeedbackMessage, setDisplayFeedbackMessage] = useState(
    displayErrors && !data?.facility?.id
  )
  useEffectThatWontRunOnMount(() => {
    setDisplayFeedbackMessage(!data?.facility?.id)
  }, [data, displayErrors])

  const onChange = updateFacilityInformation({
    supplierOrganizationId: csrInboxState.supplierOrganization.id,
    csrInboxStateId: csrInboxState.id,
  })

  const mutateFacility = useMutation({
    mutationFn: (facility: InboxStateClinicalFacility["facility"]) => {
      return onChange({ facility: facility }).then(() => {
        queryClient.setQueryData<InboxStateClinicalFacility>(
          ["csrInboxState", "facility"],
          (old) => {
            return {
              ...old,
              facility: facility || undefined,
            }
          }
        )
      })
    },
  })

  const fetchFacilities = makeApi("Supplier", csrInboxState.supplier.id)
    .fetchFacilities
  const facility = data || {}

  const field: AsyncSingleSelectionField = {
    id: "clinicalFacility",
    extractedText: facility.extractedName || "",
    label: "Facility",
    value: facility.facility,
    onChange: mutateFacility.mutateAsync,
    type: FieldType.AsyncSelection,
    fetchItems: fetchFacilities,
    feedbackMessage: displayFeedbackMessage
      ? "A facility is required to create an order"
      : undefined,
    required: true,
  }
  return <AsyncSingleSelect field={field} />
}
