// @ts-strict-ignore
import { capitalize } from "utilities/string"

export const PHONE_VALIDATION_MESSAGE =
  "must be 10 digits long and cannot start with 0 or 1"

export function formatPhone(value?: string) {
  if (!value) {
    return null
  }
  const digits = value.replace(/\D/g, "")
  return `(${digits.substring(0, 3)}) ${digits.substring(
    3,
    6
  )}-${digits.substring(6)}`
}

export function formatPhoneWithExtension(phone?: string, extension?: string) {
  if (!phone) {
    return null
  }
  const number = formatPhone(phone)
  if (!extension) {
    return number
  }
  return `${number} ext. ${extension}`
}

export function formatPhoneWithType(phoneWithType): string {
  let result = ""
  if (phoneWithType.type) {
    result += `${capitalize(phoneWithType.type)} phone - `
  }
  result += formatPhone(phoneWithType.phoneNumber)
  return result
}

function isPhonePrefixValid(value: string): boolean {
  return value !== "0" && value !== "1"
}

const FORMATTED_PHONE_REGEXP = /\(\d{3}\) \d{3}-\d{4}/
const PHONE_REGEXP = /\d{10}/

function isFormattedPhoneValid(value?: string): boolean {
  if (!isPhonePrefixValid(value[1])) {
    return false
  }
  return FORMATTED_PHONE_REGEXP.test(value)
}

export function isPhoneValid(value?: string, optional = false): boolean {
  if (optional && !value) {
    return true
  }
  if (!value) {
    return false
  }
  if (value.startsWith("(")) {
    return isFormattedPhoneValid(value)
  }
  if (!isPhonePrefixValid(value[0])) {
    return false
  }
  return PHONE_REGEXP.test(value)
}

export function normalizePhone(value: string): string {
  value = value.replace(/\D/g, "")
  if (value.startsWith("+1")) {
    return value.substr(2)
  } else if (value.startsWith("1")) {
    return value.substr(1)
  }
  return value
}
