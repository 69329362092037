export enum CancelReasons {
  NotMyPatient = "not_my_patient",
  NoLongerMedicallyNecessary = "no_longer_medically_necessary",
  Other = "other",
}

const cancelReasonLabels = {
  [CancelReasons.NotMyPatient]: "Not my patient",
  [CancelReasons.NoLongerMedicallyNecessary]: "No longer medically necessary",
  [CancelReasons.Other]: "Other",
}

export const cancelReasonRadioOptions = Object.values(CancelReasons).map(
  (reason) => ({
    label: cancelReasonLabels[reason],
    value: reason,
  })
)
