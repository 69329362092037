import React, { useState } from "react"
import Alert from "components/Alert"
import Modal from "components/Modal"
import { ChartNoteHelperType } from "../sharedTypes"

type Props = {
  type: ChartNoteHelperType
  hasDefaultChoices?: boolean
}

function ChartNoteHelper({ type, hasDefaultChoices }: Props) {
  const [show, setShow] = useState(false)
  const showModal = () => setShow(true)
  const hideModal = () => setShow(false)

  return (
    <>
      <div className="well-wide">
        <Alert
          status="info"
          leftIcon="fa-info-circle color-info"
          className="canopy-mbe-8x"
          onClick={showModal}
        >
          <strong>
            The following information is required by the supplier(s) on this
            order.
          </strong>
          <p>
            {type === ChartNoteHelperType.Prefill &&
              hasDefaultChoices &&
              "Most common conditions have been selected. "}
            {type === ChartNoteHelperType.Common &&
              hasDefaultChoices &&
              "Most common conditions have been highlighted. "}
            <span>
              Please select answers that match the patient’s condition.{" "}
            </span>
            <a className="link color-info">Learn more</a>
          </p>
        </Alert>
      </div>
      <Modal
        title="About Documentation"
        size="md"
        show={show}
        cancel={hideModal}
      >
        <Modal.Body>
          <h4>What is the purpose of documentation?</h4>
          <p className="color-dark-gray canopy-mbe-12x">
            Suppliers of medical equipment, documents, and services require
            clinical information to process and submit orders to payers for
            reimbursement.
          </p>
          <h4>Why are there variations of documentation?</h4>
          <p className="color-dark-gray">
            Each supplier may require slightly different information. Parachute
            works with connected suppliers to determine the information
            required, how that information is displayed, and the way questions
            must be completed.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-brand" onClick={hideModal}>
            Got it
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ChartNoteHelper
