// @ts-strict-ignore
import React from "react"
import ContinueButton from "applications/Workflow/components/ContinueButton"
import ConfirmButton from "./ConfirmButton"
import ChartNoteHelperAlert from "./ChartNoteHelperAlert"
import ProgressDonut from "components/ProgressDonut"
import Survey from "applications/Workflow/components/Survey"
import { Link } from "react-router-dom"
import * as routes from "applications/Workflow/routes"
import { SurveyQuestion, SurveyAnswerType } from "sharedTypes"
import { ChartNoteHelperType } from "../sharedTypes"
import {
  LEGAL_WARNING,
  CRITERIA_NOT_MET,
  CRITERIA_MET,
} from "applications/Workflow/containers/ClinicalFacilityClinical/utilities"
import WorkflowHeader from "applications/Workflow/components/WorkflowHeader"
import cx from "classnames"
import { CanopyNotice } from "@parachutehealth/canopy-notice"

type Props = {
  answerQuestion(
    question: SurveyQuestion,
    answerType: SurveyAnswerType,
    answerValue: string
  ): Promise<void>
  confirmPrefilledAnswers(): Promise<void>
  name: string
  questions: SurveyQuestion[]
  requiresConfirmation: boolean
  disqualifiedMessage?: string
  qualificationStatus: string
  percentageComplete?: number
  chartNoteHelperType: ChartNoteHelperType
  hasDefaultChoices: boolean
  nextPagePath?: string
  isOptumFlow?: boolean
}

const InternalChartNotes: React.SFC<Props> = ({
  answerQuestion,
  confirmPrefilledAnswers,
  name,
  questions,
  requiresConfirmation,
  qualificationStatus,
  disqualifiedMessage = "",
  percentageComplete,
  hasDefaultChoices,
  chartNoteHelperType,
  nextPagePath,
  isOptumFlow = false,
}) => {
  const isDisqualified = ["disqualified", "not_recommended"].includes(
    qualificationStatus
  )
  const isQualified = ["qualified", "recommended"].includes(qualificationStatus)
  const isComplete = isDisqualified || isQualified

  const renderOptumNotice = () => {
    if (isQualified) {
      return (
        <CanopyNotice
          title="Health plan criteria has been met"
          // @ts-ignore WF-26014: rework how to handle legal notice
          message={
            <span
              dangerouslySetInnerHTML={{
                __html: `${CRITERIA_MET}<br /><br /><em>${LEGAL_WARNING}</em>`,
              }}
            />
          }
          variant="success"
        />
      )
    } else if (isDisqualified) {
      return (
        <CanopyNotice
          title="Health plan criteria has not been met"
          // @ts-ignore WF-26014: rework how to handle legal notice
          message={
            <span
              dangerouslySetInnerHTML={{
                __html: `${CRITERIA_NOT_MET}<br /><br /><em>${LEGAL_WARNING}</em>`,
              }}
            />
          }
          variant="warning"
        />
      )
    } else {
      return null
    }
  }

  const renderGenericNotice = () => {
    if (isQualified) {
      return (
        <CanopyNotice
          title=""
          // @ts-ignore WF-26014: rework how to handle legal notice
          message={
            <span
              dangerouslySetInnerHTML={{
                __html: `<strong>Documentation completed.</strong><br /><br /><em>${LEGAL_WARNING}</em>`,
              }}
            />
          }
          variant="success"
        />
      )
    } else if (isDisqualified) {
      return (
        <CanopyNotice
          title=""
          // @ts-ignore WF-26014: rework how to handle legal notice
          message={
            <span
              dangerouslySetInnerHTML={{
                __html: `<strong>Documentation not supported.</strong> ${disqualifiedMessage}<br /><br /><em>${LEGAL_WARNING}</em>`,
              }}
            />
          }
          variant="error"
        />
      )
    } else {
      return null
    }
  }

  return (
    <div className={cx("wide-fixed-row")}>
      <WorkflowHeader title={name} />
      {!!questions.length && (
        <>
          <ChartNoteHelperAlert
            type={chartNoteHelperType}
            hasDefaultChoices={hasDefaultChoices}
          />
          <div className="well clearfix">
            <Survey
              questions={questions}
              answerQuestion={answerQuestion}
              showMostCommon={
                chartNoteHelperType === ChartNoteHelperType.Common
              }
            />
            {!isComplete && percentageComplete !== undefined && (
              <div className="float-right">
                <ProgressDonut percentage={percentageComplete} />
              </div>
            )}
            <ConfirmButton
              requiresConfirmation={requiresConfirmation}
              confirmPrefilledAnswers={confirmPrefilledAnswers}
            />

            {isOptumFlow ? renderOptumNotice() : renderGenericNotice()}
          </div>
        </>
      )}

      <div className="well-wide">
        <div className="row">
          <div className="col-md-6 col-12 canopy-mbe-12x order-md-6">
            <div className="text-right">
              <ContinueButton
                disabled={isOptumFlow ? !isComplete : !nextPagePath}
                path={nextPagePath}
              />
            </div>
          </div>
          <div className="col-md-6 col-12">
            {!!isComplete && (
              <Link
                className="btn btn-brand-o btn-xs-block"
                to={routes.clinicalPath()}
              >
                Back To Clinical Info
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default InternalChartNotes
