import React, { useState } from "react"
import classNames from "classnames"
import * as styles from "./index.module.scss"
import PatientPortalMessageTemplates from "./PatientPortalMessageTemplates"
import ChatDisabledHero from "./ChatDisabledHero"
import AutomatedWelcomeMessage from "./AutomatedWelcomeMessage"
import { QueryClientProvider } from "@tanstack/react-query"
import { useSupplierSettingsQuery } from "./api"
import { useStableQueryClient } from "utilities/queryClient"

interface Props {
  automatedWelcomeMessage: boolean
  supplierId: string
  supplierName: string
  messageTemplates: {
    id: string
    name: string
    template: string
  }[]
  supportEmail: string
}

enum Tabs {
  MessageTemplates = "messageTemplates",
  AutomatedWelcomeMessage = "automatedWelcomeMessage",
}

const PatientPortalMessages = ({
  supplierName,
  messageTemplates,
  supportEmail,
  supplierId,
}: Props) => {
  const [activeTab, setActiveTab] = useState<Tabs>(Tabs.MessageTemplates)

  const { data: supplierSettings } = useSupplierSettingsQuery(supplierId)

  return (
    <div>
      <div className={styles.container}>
        <ul role="tablist" className={styles.tabList}>
          <li role="presentation" className={styles.tabContainer}>
            <div
              role="tab"
              className={classNames(styles.tab, {
                [styles.tabActive]: activeTab === Tabs.MessageTemplates,
              })}
              aria-selected={activeTab === Tabs.MessageTemplates}
              onClick={() => setActiveTab(Tabs.MessageTemplates)}
            >
              Patient Message Templates {supplierName}
            </div>
          </li>
          <li role="presentation" className={styles.tabContainer}>
            <div
              role="tab"
              className={classNames(styles.tab, {
                [styles.tabActive]: activeTab === Tabs.AutomatedWelcomeMessage,
              })}
              aria-selected={activeTab === Tabs.AutomatedWelcomeMessage}
              onClick={() => setActiveTab(Tabs.AutomatedWelcomeMessage)}
            >
              Automated Welcome Message
            </div>
          </li>
        </ul>
      </div>

      {activeTab === Tabs.MessageTemplates &&
        supplierSettings?.portalMessaging && (
          <PatientPortalMessageTemplates
            supplierName={supplierName}
            messageTemplates={messageTemplates}
          />
        )}

      {activeTab === Tabs.MessageTemplates &&
        supplierSettings?.portalMessaging === false && (
          <ChatDisabledHero
            supplierName={supplierName}
            supportEmail={supportEmail}
          />
        )}

      {activeTab === Tabs.AutomatedWelcomeMessage && (
        <AutomatedWelcomeMessage supplierId={supplierId} />
      )}
    </div>
  )
}

const PatientPortalMessagesWithQueryClientProvider = (props: Props) => {
  const queryClient = useStableQueryClient()

  return (
    <QueryClientProvider client={queryClient}>
      <PatientPortalMessages {...props} />
    </QueryClientProvider>
  )
}

export default PatientPortalMessagesWithQueryClientProvider
