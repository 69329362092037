import React from "react"
import Modal from "components/Modal"
import { Button, Form, TextArea, RadioButtons } from "components/form"
import { underscore } from "utilities/string"
import Alert from "components/Alert"

interface Props {
  cancel(): void
  onSubmit(values: FeedbackModalValues): void
  show: boolean
  topics?: string[]
}

interface FeedbackModalValues {
  feedback: string
  topic?: string
}

const FeedbackModal: React.FC<Props> = ({ cancel, onSubmit, show, topics }) => {
  const initialValues: FeedbackModalValues = { feedback: "", topic: "" }

  const Topics = () =>
    topics ? (
      <RadioButtons
        label="Please select a topic you’d like to give feedback on:"
        name="topic"
        className="plain"
        options={topics.map((topic) => ({
          label: topic,
          value: underscore(topic),
        }))}
      />
    ) : (
      <></>
    )

  const submitDisabled = (values: FeedbackModalValues): boolean => {
    if (!topics) return !values.feedback

    return !values.topic || !values.feedback
  }

  return (
    <Modal show={show} cancel={cancel} title="Product Feedback">
      <Form initialValues={initialValues} onSubmit={onSubmit}>
        {({ values }) => (
          <>
            <Modal.Body>
              <Alert status="info" className="canopy-mbe-8x">
                <strong>Heads up!</strong> If you&apos;re looking for assistance
                on something, please use the Help Center button in the
                bottom-right corner of the screen.
              </Alert>
              <Topics />
              <TextArea
                label="Share feedback directly to the teams building Parachute."
                name="feedback"
                placeholder="Share your feedback here"
                rows={1}
                alert={
                  <span className="font-subparagraph color-dark-gray">
                    Do not share sensitive patient information
                  </span>
                }
              />
            </Modal.Body>
            <Modal.Footer>
              <div className="d-flex justify-content-space-between">
                <Button className="btn-secondary" onClick={cancel}>
                  Cancel
                </Button>
                <Button
                  className="btn-brand"
                  type="submit"
                  disabled={submitDisabled(values as FeedbackModalValues)}
                >
                  Done
                </Button>
              </div>
            </Modal.Footer>
          </>
        )}
      </Form>
    </Modal>
  )
}

export default FeedbackModal
