import React from "react"
import ToggleFilter from "components/ToggleFilter"
import { EventCategory, trackEvent } from "../../../../../utilities/tracking"

export const OriginatorToggle: React.FC<{
  initialOriginator: string
  setInitialOriginator(originator: string): void
}> = ({ initialOriginator, setInitialOriginator }) => {
  const onChange = async (values) => {
    if (values.originator === true) {
      setInitialOriginator("provider")
      trackEvent(
        EventCategory.ClinicalFacilityDashboard,
        "created_by_facility_filter_enabled"
      )
    } else if (values.originator === false) {
      setInitialOriginator("csr")
      trackEvent(
        EventCategory.ClinicalFacilityDashboard,
        "created_by_supplier_filter_enabled"
      )
    } else {
      setInitialOriginator("")
    }
  }

  return (
    <ToggleFilter
      name="originator"
      options={[
        { label: "Created by Facility", value: true },
        { label: "Created by Supplier", value: false },
      ]}
      initialValue={
        {
          ["provider"]: true,
          ["csr"]: false,
        }[initialOriginator]
      }
      onChange={onChange}
    />
  )
}
