// @ts-strict-ignore
import React, { useState } from "react"
import classNames from "classnames"
import { getKeyCode } from "utilities/browser/event"
import { Keycode } from "enums/keycodes"
import MaskedInput from "components/MaskedInput"
import { getClipboardData } from "utilities/clipboard"
import { TextFieldType } from "./form/sharedTypes"

type Mask = string | RegExp

type Props = {
  onChange: React.ChangeEventHandler<HTMLInputElement>
  onBlur?: React.FormEventHandler
  onPaste?: React.ChangeEventHandler<HTMLInputElement>
  onKeyDown?: React.KeyboardEventHandler
  onKeyUp?: React.KeyboardEventHandler
  onConfirm?(value: string): void
  value?: string
  disabled?: boolean
  autoComplete?: string
  autoFocus?: boolean
  id?: string
  name?: string
  mask?: Mask[]
  placeholder?: string
  className?: string
  inputMode?: string
  buttonId?: string
  type?: TextFieldType
  ref?: React.Ref<HTMLInputElement>
  onClear?: () => void
}

function InputGroup({ showButton, showClearButton, children }) {
  if (showButton) {
    return <div className="input-group">{children}</div>
  }
  if (showClearButton) {
    return <div className="input-group">{children}</div>
  }
  return <div className="input-group-placeholder">{children}</div>
}

const TextInput: React.FunctionComponent<Props> = React.forwardRef<
  HTMLInputElement,
  Props
>(
  (
    {
      onChange,
      onBlur,
      onPaste,
      onKeyDown,
      onKeyUp,
      onConfirm,
      value,
      disabled,
      autoComplete,
      autoFocus,
      id,
      name,
      placeholder,
      className,
      mask,
      inputMode,
      buttonId,
      type,
      onClear,
    },
    ref
  ) => {
    const [showButton, setButtonVisibility] = useState(false)
    const [showClearButton, setShowClearButton] = useState(onClear && !!value)

    const handleChange = (event) => {
      if (onConfirm) {
        onClear && setShowClearButton(false)
        setButtonVisibility(true)
      }
      onChange && onChange(event)
    }

    const confirm = () => {
      if (onConfirm) {
        setButtonVisibility(false)
        onConfirm(value)
        onClear && setShowClearButton(true)
      }
    }

    const clearField = (event) => {
      onClear && onClear()
      onChange && onChange(event)
      onConfirm && onConfirm("")
      setShowClearButton(false)
    }

    const handleKeyPress = (event) => {
      if (getKeyCode(event) === Keycode.Enter) {
        confirm()
      }
    }

    const handlePaste = (event) => {
      if (onPaste) {
        event.preventDefault()
        event.target.value = getClipboardData(event)
        onPaste(event)
        onChange && onChange(event)
      }
    }

    const inputProps = {
      className: classNames("form-control", className),
      type: type || "text",
      id,
      name,
      value,
      placeholder,
      onChange: handleChange,
      onBlur,
      onPaste: handlePaste,
      onKeyDown,
      onKeyUp,
      onKeyPress: handleKeyPress,
      disabled,
      autoComplete,
      autoFocus,
    }

    return (
      <InputGroup showButton={showButton} showClearButton={showClearButton}>
        {mask ? (
          <MaskedInput
            {...inputProps}
            inputMode={inputMode}
            mask={mask}
            render={(innerRef, props) => {
              return (
                <input
                  ref={(node) => {
                    if (ref) {
                      ;(ref as any)(node)
                    }
                    innerRef(node)
                  }}
                  {...props}
                />
              )
            }}
          />
        ) : (
          <input {...inputProps} ref={ref} />
        )}
        {showButton && (
          <div className="input-group-append">
            <button
              type="button"
              id={buttonId}
              className="btn btn-brand"
              onClick={confirm}
            >
              Save
            </button>
          </div>
        )}
        {showClearButton && (
          <div className="input-group-append">
            <button
              type="button"
              id={`clear ${name}`}
              className="btn btn-secondary"
              onClick={clearField}
            >
              Clear
            </button>
          </div>
        )}
      </InputGroup>
    )
  }
)

export default TextInput
