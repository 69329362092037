import React from "react"
import TooltipTrigger from "react-popper-tooltip"
import classNames from "classnames"

type Props = {
  triggerElementClassName?: string
  hideTooltipAtBreakpoint?: "xs" | "sm" | "md" | "lg" | "xl"
  trigger?: string | string[]
  width?: Width
  placement?: Placement
  overlay: React.ReactNode
  children?: React.ReactNode
  disabled?: boolean
  defaultTooltipShown?: boolean
}

enum Width {
  Unlimited,
  Wide,
  LimitedWide,
}

enum Placement {
  Top = "top",
  Right = "right",
  Bottom = "bottom",
  Left = "left",
}

const Tooltip = ({
  triggerElementClassName,
  hideTooltipAtBreakpoint,
  children,
  width,
  overlay,
  trigger,
  placement,
  disabled,
  defaultTooltipShown,
}: Props) => {
  return disabled ? (
    <>{children}</>
  ) : (
    <TooltipTrigger
      defaultTooltipShown={defaultTooltipShown}
      trigger={overlay ? (trigger as any) : []}
      placement={placement}
      tooltip={({
        arrowRef,
        tooltipRef,
        getArrowProps,
        getTooltipProps,
        placement,
      }) => (
        <div
          role="tooltip"
          {...getTooltipProps({
            ref: tooltipRef,
            className: classNames(
              "tooltip in",
              placement,
              hideTooltipAtBreakpoint
                ? `d-${hideTooltipAtBreakpoint}-none`
                : null,
              {
                "tooltip-max-width-unlimited": width === Width.Unlimited,
                "tooltip-max-width-wide": width === Width.LimitedWide,
                "tooltip-min-width-wide": width === Width.Wide,
              }
            ),
          })}
        >
          <div
            {...getArrowProps({
              ref: arrowRef,
              className: "tooltip-arrow",
              "data-placement": placement,
            })}
          />
          <div className="tooltip-inner">{overlay}</div>
        </div>
      )}
    >
      {({ getTriggerProps, triggerRef }) => (
        <div
          {...getTriggerProps({
            ref: triggerRef,
            className: classNames(triggerElementClassName, "trigger"),
          })}
        >
          {children}
        </div>
      )}
    </TooltipTrigger>
  )
}

Tooltip.defaultProps = {
  placement: "top",
}

Tooltip.Width = Width
Tooltip.Placement = Placement

export default Tooltip
