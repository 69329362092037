import React, { useState } from "react"
import AsyncSelect from "react-select/async"
import classNames from "classnames"
import { highlight } from "../../utilities/select"
import { fetchPossibleSalesReps } from "./api"
import { useDebounce } from "../../hooks/useDebounce"
import Link from "components/Link"

type SalesRep = {
  id: number
  name: string
  email: string
}

type Props = {
  currentSalesRep: SalesRep
  editTerritoryPath: string
  salesRepControlledByTerritory: boolean
  territoriesDataPropagationEnabled: boolean
}

type TerritorySubLabelProps = {
  editTerritoryPath: string
}

const TerritorySubLabel = (props: TerritorySubLabelProps) => {
  return (
    <div className="sub-label">
      To change the sales rep on this facility, update the&nbsp;
      <Link
        href={props.editTerritoryPath}
        target="_blank"
        className="color-brand"
      >
        territory
      </Link>
      .
    </div>
  )
}

const AsyncSalesRepSelect = (props: Props) => {
  const convertToOption = (user) => {
    if (user && user.id) {
      return {
        value: user.id,
        label: `${user.name} (${user.email})`,
        ...user,
      }
    } else {
      return {
        value: "",
        label: "Select...",
      }
    }
  }

  const ALL_SEARCH = ""
  const renderSuggestion = (suggestion, { query, isSelected }) => (
    <div className={classNames({ highlight: isSelected })}>
      {highlight(suggestion.label, query)}
    </div>
  )

  const [selectedSalesRep] = useState(convertToOption(props.currentSalesRep))
  const [query, setQuery] = useState(ALL_SEARCH)

  const shouldDisplayTerritorySubLabel =
    props.territoriesDataPropagationEnabled &&
    props.salesRepControlledByTerritory

  const debouncedFetchPossibleSalesReps = useDebounce(fetchPossibleSalesReps)
  const promiseOptions = () =>
    new Promise((resolve) => {
      resolve(
        debouncedFetchPossibleSalesReps(query).then(({ data }) => {
          return data.map(convertToOption)
        })
      )
    })

  return (
    <div className="form-group">
      <label className="col-form-label">External Sales User</label>
      {shouldDisplayTerritorySubLabel && (
        <TerritorySubLabel editTerritoryPath={props.editTerritoryPath} />
      )}
      <AsyncSelect
        id="sales_rep_select"
        isDisabled={shouldDisplayTerritorySubLabel}
        isSearchable
        defaultOptions
        cacheOptions
        isClearable
        loadOptions={promiseOptions}
        onInputChange={(value) => setQuery(value)}
        defaultValue={selectedSalesRep}
        name="fulfillment_agreement[external_sales_user_id]"
        placeholder="Select..."
        className="select2-selection select2-selection--multiple"
        renderOption={renderSuggestion}
      />
    </div>
  )
}

export default AsyncSalesRepSelect
