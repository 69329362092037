import { employerPrefix } from "utilities/url"

export const inboxDashboardUrl = (
  supplierOrganizationId: string,
  supplierId: string
): string =>
  `${employerPrefix(
    "SupplierOrganization",
    supplierOrganizationId
  )}/inbox/${supplierId}`

export const updatePatientAddressUrl = (
  supplierOrganizationId: string,
  csrInboxStateId: string
) =>
  `${employerPrefix(
    "SupplierOrganization",
    supplierOrganizationId
  )}/inbox_documents/${csrInboxStateId}/patient_address`

export const createOrderUrl = (
  supplierOrganizationId: string,
  csrInboxStateId: string
) =>
  `${employerPrefix(
    "SupplierOrganization",
    supplierOrganizationId
  )}/inbox_documents/${csrInboxStateId}/create_order`

export const orderUrl = (supplierId: string, orderId: string) =>
  `${employerPrefix("Supplier", supplierId)}/orders/${orderId}`
