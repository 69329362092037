import React from "react"
import {
  Drawer,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core"
import classNames from "classnames"
import { CatalogSeed } from "../../../../types/sharedTypes"
import * as styles from "./index.module.scss"
import { canopyColorPrimitivesGray94 } from "@parachutehealth/canopy-tokens-color"
import { CanopyIcon } from "@parachutehealth/canopy-icon"
import { pluralize } from "../../../../../../utilities/string"

export type SeedPackagesDrawerProps = {
  catalogSeed: CatalogSeed
  open: boolean
  onClose(): void
}

const SeedPackagesDrawer = ({
  open,
  onClose,
  catalogSeed,
}: SeedPackagesDrawerProps) => {
  return (
    <Drawer
      variant="persistent"
      open={open}
      anchor="right"
      className={classNames(styles.seedPackagesDrawer)}
      transitionDuration={500}
    >
      <div className="canopy-p-12x d-flex justify-content-space-between align-items-center border-bottom bg-default">
        <div className="canopy-typography-heading-large canopy-mie-4x canopy-mbe-0">
          {catalogSeed.name} Seed: Packages
        </div>
        <button
          aria-label="Close"
          className="close"
          type="button"
          onClick={onClose}
        >
          <CanopyIcon
            name="xmark"
            size="medium"
            fill="canopyColorPrimitivesGray27"
          ></CanopyIcon>
        </button>
      </div>
      <div className="canopy-p-8x">
        <div className={classNames(styles.buttonContainer)}>
          <div>
            <div className="canopy-typography-heading-medium canopy-mbe-4x">
              Seed Packages
            </div>
            <Typography>
              This seed contains{"  "}
              <span className="font-weight-bold">
                {`${catalogSeed.packages!.length} ${pluralize(
                  "package",
                  catalogSeed.packages!.length
                )}`}
              </span>
            </Typography>
          </div>
        </div>
        {catalogSeed.packages && (
          <TableContainer
            style={{
              border: `1px solid ${canopyColorPrimitivesGray94}`, // TODO: replace with semantic Canopy border token when available
              borderRadius: "5px",
            }}
          >
            <Table
              data-testid="seed-packages-table"
              size="small"
              className={classNames(styles.table)}
            >
              <TableHead className={classNames(styles.tableHead)}>
                <TableRow>
                  <TableCell component="th" scope="col">
                    Package
                  </TableCell>
                  <TableCell component="th" scope="col">
                    Package ID
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {catalogSeed.packages.map((catalogPackage) => (
                  <TableRow key={catalogPackage.id}>
                    <TableCell>
                      <a href={catalogPackage.url}>{catalogPackage.name}</a>
                    </TableCell>
                    <TableCell>{catalogPackage.externalId}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </Drawer>
  )
}
export default SeedPackagesDrawer
