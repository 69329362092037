import React from "react"
import FocusTile from "./FocusTile"
import * as styles from "./FocusTiles.module.scss"
import { useFeatureFlags } from "components/FeatureFlagContext"
import { getIncomingOrdersCount } from "applications/ClinicalFacilityDashboard/api"
import { QueryClientProvider, QueryClient } from "@tanstack/react-query"
import { createdBySupplierSubpageUrl } from "./urls"
import { incomingOrdersUrl } from "components/ClinicalDashboard/urls"

const queryClient = new QueryClient()

type Props = {
  clinicalFacilityId: string
  handleIncomingOrdersSuperfilterClick: () => void
  selectedFocusTile: "incomingOrders" | null
  incomingOrdersEnabled: boolean
}

const FocusTiles: React.FC<Props> = ({
  clinicalFacilityId,
  handleIncomingOrdersSuperfilterClick,
  selectedFocusTile,
  incomingOrdersEnabled,
}: Props) => {
  const { getFlagValue, isFeatureEnabled } = useFeatureFlags()
  const focusTilesFeatureFlagValue = getFlagValue("facilityDashboardFocusTiles")

  if (!focusTilesFeatureFlagValue) return null

  const subpageTilesEnabled = focusTilesFeatureFlagValue === "subpage"
  const superfilterTilesEnabled = focusTilesFeatureFlagValue === "superfilter"
  const legacyTileEnabled = focusTilesFeatureFlagValue === "legacy"

  function mockGetCount(): Promise<{ count: number }> {
    return new Promise((resolve) => {
      // Simulate an asynchronous call to get the count
      setTimeout(() => {
        resolve({ count: 0 })
      }, 1000) // Resolve after 1 second
    })
  }

  const isIncomingOrdersEnabledViaSetting =
    isFeatureEnabled("incomingOrdersEnabledSetting") && incomingOrdersEnabled
  const isFacilityIncomingOrders =
    !isFeatureEnabled("incomingOrdersEnabledSetting") &&
    isFeatureEnabled("facilityIncomingOrders")

  return (
    <QueryClientProvider client={queryClient}>
      <div className={styles.flexContainer}>
        {subpageTilesEnabled && (
          <FocusTile
            getCount={getIncomingOrdersCount}
            title="Incoming orders"
            subtitle="Orders sent from suppliers that require more information."
            href={createdBySupplierSubpageUrl(clinicalFacilityId)}
          />
        )}
        {superfilterTilesEnabled && (
          <FocusTile
            getCount={getIncomingOrdersCount}
            title="Incoming orders"
            subtitle="Orders sent from suppliers that require more information."
            selected={selectedFocusTile === "incomingOrders"}
            onClick={handleIncomingOrdersSuperfilterClick}
          />
        )}
        {legacyTileEnabled &&
          (isIncomingOrdersEnabledViaSetting || isFacilityIncomingOrders) && (
            <FocusTile
              getCount={mockGetCount}
              title="Incoming orders"
              subtitle="Orders sent from suppliers that require more information."
              href={incomingOrdersUrl}
            />
          )}
      </div>
    </QueryClientProvider>
  )
}

export default FocusTiles
