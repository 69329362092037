import React, { useContext, useEffect } from "react"
import {
  Link,
  RouterChildContext,
  useHistory,
  useParams,
} from "react-router-dom"
import {
  CatalogConsignmentCloset,
  CatalogSupplierPackageConfiguration,
} from "../../../../types/sharedTypes"
import { isNullOrUndefined } from "../../../../../../utilities/isNullOrUndefined"
import {
  duplicateConsignmentClosetSupplierPackageConfiguration,
  getConsignmentCloset,
} from "../../../../api/consignmentClosets"
import { NoticeContext } from "../../../../contexts/NoticeContext"
import { Backdrop, CircularProgress, Grid } from "@material-ui/core"
import ContentArea from "../../../../components/ContentArea"
import { canopyColorPrimitivesGray94 } from "@parachutehealth/canopy-tokens-color"
import { CanopyTextInputField } from "@parachutehealth/canopy-text-input-field"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { consignmentClosetDetailUrl } from "../../../../urls/consignmentClosets"
import { Form } from "../../../../../../components/form"
import { StaticFieldDefinition } from "../../../../types/form/sharedTypes"
import FormSegment from "../../../../components/Formik/FormSegment"
import { generateInitialValues } from "../../../../utilities/form"

type InternalConsignmentClosetSPCDuplicateFormProps = {
  consignmentCloset: CatalogConsignmentCloset
  supplierPackageConfiguration: CatalogSupplierPackageConfiguration
}
const InternalConsignmentClosetSPCDuplicateForm: React.FC<InternalConsignmentClosetSPCDuplicateFormProps> = (
  props: InternalConsignmentClosetSPCDuplicateFormProps
): React.JSX.Element => {
  const { consignmentCloset, supplierPackageConfiguration } = props
  const { showNotice } = useContext(NoticeContext)

  const history: RouterChildContext["router"]["history"] = useHistory()

  const fields: StaticFieldDefinition[] = [
    {
      type: "text",
      name: "name",
      required: true,
      label: "New Package Configuration name",
    },
  ]

  const initialValues = { ...generateInitialValues(fields, {}) }

  const handleSubmit = async (
    params: { [p: string]: any },
    { setErrors }: any
  ) => {
    const cleanedValues: Record<string, any> = {}

    // name isn't required on the backend, but we want to enforce it here
    if (!params.name) {
      setErrors({ name: "This field is required" })
      return
    }

    fields.forEach((field) => {
      cleanedValues[field.name] = params[field.name]
    })

    const response = await duplicateConsignmentClosetSupplierPackageConfiguration(
      consignmentCloset.externalId,
      supplierPackageConfiguration.externalId,
      cleanedValues
    )

    if (response.errors) {
      setErrors(response.errors)
    } else {
      history.push(consignmentClosetDetailUrl(consignmentCloset.externalId))
      showNotice(
        `New Supplier Package Configuration has been created`,
        "success",
        {
          text: "View Supplier Package Configuration",
          href: response.supplierPackageConfiguration.url,
        }
      )
    }
  }

  return (
    <>
      <h1 className="canopy-typography-heading-xlarge canopy-mt-4x canopy-mbe-4x">
        Duplicate configuration
      </h1>

      <div className="canopy-typography-body-medium canopy-mt-4x canopy-mbe-4x">
        Create and replace with a new Package Configuration based on the
        existing one
      </div>

      <ContentArea
        style={{ backgroundColor: canopyColorPrimitivesGray94 }}
        variant="medium"
        className="canopy-mbe-6x"
      >
        <Grid container component="dl" spacing={1}>
          <Grid item xs={6} component="dt" className="canopy-pin-8x">
            <b>Closet</b>
          </Grid>
          <Grid item xs={6} component="dd" className="canopy-pin-8x">
            {consignmentCloset.name}
          </Grid>
          <Grid item xs={6} component="dt" className="canopy-pin-8x">
            <b>Package to duplicate</b>
          </Grid>
          <Grid item xs={6} component="dd" className="canopy-pin-8x">
            {supplierPackageConfiguration?.packageName}
          </Grid>
          <Grid item xs={6} component="dt" className="canopy-pin-8x">
            <b>Package Configuration to duplicate</b>
          </Grid>
          <Grid item xs={6} component="dd" className="canopy-pin-8x">
            {supplierPackageConfiguration?.name || "(no name)"}
          </Grid>
        </Grid>
      </ContentArea>

      <ContentArea variant="medium">
        <Form
          initialValues={initialValues}
          enableReinitialize
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <>
              <CanopyTextInputField
                size="small"
                required
                className="canopy-mbe-16x"
                label="Closet"
                disabled
                value={consignmentCloset.name}
              />
              <FormSegment bottomMarginClass="canopy-mbe-16x">
                {fields}
              </FormSegment>

              <div>
                <CanopyButton
                  type="submit"
                  size="small"
                  variant="primary"
                  loading={isSubmitting}
                  className="canopy-mie-4x"
                >
                  Duplicate
                </CanopyButton>
                <CanopyButton
                  size="small"
                  as={Link}
                  disabled={isSubmitting}
                  to={consignmentClosetDetailUrl(consignmentCloset.externalId)}
                  variant="tertiary"
                >
                  Cancel
                </CanopyButton>
              </div>
            </>
          )}
        </Form>
      </ContentArea>
    </>
  )
}

const ConsignmentClosetSPCDuplicateForm: React.FC = (): React.JSX.Element => {
  type State = {
    consignmentCloset?: CatalogConsignmentCloset
    supplierPackageConfiguration?: CatalogSupplierPackageConfiguration
  }

  const { showNotice } = useContext(NoticeContext)
  const { consignmentClosetId, supplierPackageConfigurationId } = useParams()

  const [componentState, setComponentState] = React.useState<State>({})
  const [loading, setLoading] = React.useState<boolean>(
    isNullOrUndefined(componentState)
  )

  const loadConsignmentCloset = async (
    consignmentClosetId: string | number
  ) => {
    await getConsignmentCloset(consignmentClosetId)
      .then((consignmentCloset) => {
        // hacky, but we don't yet have a good endpoint for getting SPCs or CCSPCs.
        const supplierPackageConfiguration = consignmentCloset.supplierPackageConfigurations?.find(
          (spc) => spc.externalId === supplierPackageConfigurationId
        )
        if (!supplierPackageConfiguration) {
          showNotice(
            `Cannot find a Supplier Package Configuration matching ${supplierPackageConfigurationId} for this closet`,
            "danger"
          )
          return
        }
        setComponentState((prev) => {
          return { ...prev, consignmentCloset, supplierPackageConfiguration }
        })
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    void loadConsignmentCloset(consignmentClosetId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consignmentClosetId])

  if (loading || !componentState.supplierPackageConfiguration) {
    return (
      <Backdrop style={{ zIndex: 999 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  } else {
    return (
      <InternalConsignmentClosetSPCDuplicateForm
        consignmentCloset={componentState.consignmentCloset!}
        supplierPackageConfiguration={
          componentState.supplierPackageConfiguration!
        }
      />
    )
  }
}

export default ConsignmentClosetSPCDuplicateForm
