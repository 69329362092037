import React, { useContext, useState } from "react"
import { Context } from "sharedTypes"
import GlobalContext from "context/Global"
import { Permissions } from "../../sharedTypes"
import Icon from "components/Icon"
import NotificationsSignUpModal from "../NotificationsSignUpModal"
import { EventCategory, trackEvent } from "utilities/tracking"
import { navigate } from "utilities/navigation"
import * as urls from "applications/FacilitySignUp/AccountCompletion/urls"

type Props = {
  permissions: Permissions
}

const NotificationsSignUpButton = ({ permissions }: Props) => {
  const [showModal, setShowModal] = useState(false)

  const {
    dmeOrderSpecific,
    showJoinFacilityButton,
    currentEmployer,
  }: Context = useContext(GlobalContext)

  const shouldShowButton = () => {
    return dmeOrderSpecific && permissions.pullBack
  }

  const signUpforNotificaitons = () => {
    setShowModal(true)
    trackEvent(
      EventCategory.PushbackSignUp,
      "sign_up_notifications_button_selected_pushback"
    )
  }

  const joinFacility = () => {
    void trackEvent(
      EventCategory.PushbackSignUp,
      "join_facility_notification_button_clicked_pushback"
    )
    navigate(
      urls.joinFacilityUrl({ default_facility_id: currentEmployer.employerId })
    )
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const button = (onClick) => {
    return (
      <button
        className="btn btn-block btn-brand"
        type="button"
        onClick={onClick}
      >
        <Icon type="bell" className="canopy-mie-4x" />
        Turn on order notifications
      </button>
    )
  }

  const renderButton = () => {
    return showJoinFacilityButton ? (
      button(joinFacility)
    ) : (
      <>
        {showModal && (
          <NotificationsSignUpModal show={showModal} dismiss={closeModal} />
        )}
        {button(signUpforNotificaitons)}
      </>
    )
  }

  return shouldShowButton() ? renderButton() : null
}

export default NotificationsSignUpButton
