// @ts-strict-ignore
import React, { useState, useEffect } from "react"
import { Field } from "formik"
import { wrap } from "utilities/array"
import asQuestion from "./asQuestion"
import MostCommonCallout from "./MostCommonCallout"
import Checkboxes from "components/form/Checkboxes"
import TextArea from "components/form/TextArea"
import { SurveyOption, SurveyQuestion, SurveyAnswerType } from "sharedTypes"
import { Values } from "../sharedTypes"
import { isTab, isEnter } from "utilities/browser/event"

const OTHER_VALUE = "OTHER"

function isDefaultChoice(choice, question) {
  return (
    question.defaultChoices &&
    question.defaultChoices.find(
      (defaultChoice) => defaultChoice === choice.code
    )
  )
}

function getOptions(question, answers, autoFocus, showMostCommon, dirty) {
  answers = wrap(answers)
  const options: SurveyOption[] = question.choices.map((choice) => {
    const option: SurveyOption = {
      label: choice.label,
      value: choice.code,
    }
    if (showMostCommon && isDefaultChoice(choice, question)) {
      option.callout = <MostCommonCallout />
    }
    return option
  })
  if (question.other) {
    options.push({
      label: question.other,
      value: OTHER_VALUE,
      addOnPosition: "inside",
      addOn: ({ field, form }) => {
        if (!field.value.includes(OTHER_VALUE)) {
          return
        }
        return (
          <TextArea
            name="otherAnswer"
            onKeyDown={(event) =>
              dirty && (isTab(event) || isEnter(event)) && form.handleSubmit()
            }
          />
        )
      },
    })
  }
  return options
}

type Props = {
  question: SurveyQuestion
  setFieldValue(field: string, value, shouldValidate?: boolean): void
  inputId: string
  isSubmitting: boolean
  showMostCommon?: boolean
  dirty: boolean
  requiresConfirmation: boolean
}

const QuestionSelectMulti: React.SFC<Props> = ({
  question,
  setFieldValue,
  inputId,
  isSubmitting,
  showMostCommon,
  dirty,
}) => {
  const [isFirstRender, setIsFirstRender] = useState(true)
  useEffect(() => {
    setIsFirstRender(false)
  }, [])

  const options = getOptions(
    question,
    question.answerValue || [],
    !isFirstRender,
    showMostCommon,
    dirty
  )
  return (
    <>
      <Checkboxes
        id={inputId}
        name="answerValue"
        options={options}
        onChange={() => {
          setFieldValue("na", false)
        }}
        disabled={isSubmitting}
      />
      {question.naEnabled && (
        <Field name="na">
          {({ form, field }) => (
            <>
              <hr className="canopy-mbs-0 canopy-mbe-8x" />
              <div className="checkbox radio unframed primary block">
                <input
                  {...field}
                  type="checkbox"
                  id={`${inputId}-na`}
                  checked={field.value}
                  onChange={(event) => {
                    if (event.target.checked) {
                      form.setFieldValue("answerValue", [])
                      form.setFieldValue("otherAnswer", "")
                    }
                    field.onChange(event)
                  }}
                />
                <label htmlFor={`${inputId}-na`}>
                  {question.naLabel || "None of these"}
                  {showMostCommon &&
                    question.defaultChoices?.includes("na") && (
                      <MostCommonCallout />
                    )}
                </label>
              </div>
            </>
          )}
        </Field>
      )}
    </>
  )
}
const config = {
  mapValuesToQuestion: (values: Values) => {
    const { na, otherAnswer } = values
    const answerValue = values.answerValue as string[]
    const answers = [
      ...answerValue.filter((a) => a !== OTHER_VALUE),
      answerValue.includes(OTHER_VALUE) ? otherAnswer : null,
    ].filter(Boolean)
    return {
      answerType: na ? SurveyAnswerType.Na : SurveyAnswerType.Value,
      answerValue: na ? null : answers,
    }
  },
  mapQuestionToValues: (question: SurveyQuestion) => {
    let allAnswers
    if (typeof question.answerValue === "string") {
      allAnswers = [question.answerValue]
    } else {
      allAnswers = question.answerValue || []
    }
    const staticChoices = question.choices.map(({ code }) => code)
    const otherAnswer = allAnswers.find((a) => !staticChoices.includes(a)) || ""
    const answerValue = allAnswers.map((answer) =>
      answer === otherAnswer ? OTHER_VALUE : answer
    )
    const na = question.answerType === SurveyAnswerType.Na
    return { na, otherAnswer, answerValue }
  },
  validate: (values: Values) => {
    const answerValue = values.answerValue as string[]
    if (answerValue.includes(OTHER_VALUE) && !values.otherAnswer) {
      return { otherAnswer: "is invalid" }
    }
    return {}
  },
  isButtonVisible: ({ dirty, errors }) => dirty && !errors.answerValue,
  submitHelpText: <></>,
}

export default asQuestion(QuestionSelectMulti, config)
