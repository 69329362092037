import React, { useState } from "react"
import Modal from "components/Modal"
import { Form, TextField } from "components/form"
import Overlay from "components/Overlay"

type Props = {
  updateSupplierSystemClinicalFacilityId(
    externalSystemId: string
  ): Promise<void>
  close(): void
}

const SupplierSystemClinicalFacilityExternalSystemIdModal = ({
  close,
  updateSupplierSystemClinicalFacilityId,
}: Props) => {
  const [showOverlay, setShowOverlay] = useState(false)
  const onSubmit = ({ externalSystemId }) => {
    setShowOverlay(true)
    return updateSupplierSystemClinicalFacilityId(externalSystemId)
      .then(() => close())
      .catch(() => setShowOverlay(false))
  }
  return (
    <Modal
      show
      cancel={close}
      closeable
      title="Set External System ID"
      size="md"
    >
      <Overlay
        active={showOverlay}
        showSpinner
        position="top"
        className="overlay-flex-height"
      >
        <Form onSubmit={onSubmit} initialValues={{ externalSystemId: "" }}>
          <Modal.Body>
            <TextField label="External System ID" name="externalSystemId" />
          </Modal.Body>
          <Modal.Footer>
            <div className="float-right">
              <button type="submit" className="btn btn-primary">
                Save
              </button>
            </div>
          </Modal.Footer>
        </Form>
      </Overlay>
    </Modal>
  )
}

export default SupplierSystemClinicalFacilityExternalSystemIdModal
