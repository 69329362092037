import React from "react"
import { employerHomeUrl } from "applications/Workflow/urls"
import BackArrowIcon from "components/BackArrowIcon"
import { trackClick } from "../../analytics"
import NonProdHeader from "applications/Workflow/containers/Navigation/components/TopNavigation/TopLeftNavButton/NonProdHeader/NonProdHeader"

interface Props {
  label: string
  nonprodHeaderDetails?: string
}

const HomeLink: React.FC<Props> = ({ label, nonprodHeaderDetails }) => {
  return (
    <>
      <a
        className="btn btn-ghost canopy-px-4x"
        href={employerHomeUrl()}
        onClick={() => trackClick(label)}
      >
        <BackArrowIcon />
        {label}
      </a>
      <NonProdHeader nonprodHeaderDetails={nonprodHeaderDetails} />
    </>
  )
}

export default HomeLink
