// @ts-strict-ignore
import React, { useCallback, useMemo, useState } from "react"
import { Grid } from "@material-ui/core"
import { MaterialThemeProvider, useStyles } from "themes/theme"
import Header from "./components/Header"
import { ToastProvider } from "components/Toaster"
import { withBrowserRouter } from "routers/BrowserRouter"
import { Route, Switch } from "react-router-dom"
import { BaseRoute } from "./routes"
import SignatureRequestSignTab from "./components/SignatureRequestSignTab"
import SignatureRequestReviewTab from "./components/SignatureRequestReviewTab"
import SignatureRequestDoneTab from "./components/SignatureRequestDoneTab"
import { Doctor, SidebarListDoctor, Tab } from "./sharedTypes"
import { checkForActionableSignatureRequests } from "./api"
import LeftSidebar from "components/ClinicalDashboard/LeftSidebar"
import { FlashProvider, useFlashContext } from "./hooks/useFlashContext"
import FacilitySignup from "./components/FacilitySignup"
import { DoctorProvider } from "./hooks/useDoctorContext"
import Alerts from "./components/Alerts"
import ReviewerAgreementWrapper from "./components/ReviewerAgreement/ReviewerAgreementWrapper"
import { handleError } from "utilities/error"
import { promoHeightCalculation } from "./utilities/heightCalculation"

const styles = (flash: boolean) => (theme) => ({
  container: {
    height: "100%",
    width: "100%",
    flexDirection: "column",
    display: "flex",
  },
  promoContainer: {
    "@media (min-height: 700px) and (min-width: 800px)": {
      height: promoHeightCalculation(flash),
    },
    height: "100%",
    width: "100%",
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  header: { flexGrow: 0 },
  body: { flexGrow: 1, overflowX: "auto", marginTop: theme.spacing(1) },
})

export interface Props {
  initialDoctor: Doctor
  featureFlags: {
    incomingOrdersEnabledSetting: boolean
    facilityIncomingOrders: boolean
    newPillIncomingOrders: boolean
  }
  currentUserEligibleForFacilitySignup: boolean
  optIn?: boolean
  reviewer: {
    id: string
    email: string
    name: string
    facilityEmploymentEmployerId?: string
  }
  redirectToFacilitySignupPage: boolean
  actionableSignatureRequestsStatus: { review: boolean; sign: boolean }
  sidebarInformation: {
    incomingOrdersEnabled: boolean
    isReviewer: boolean
    lastAccessedFacilityEmployerId?: string
    doctors?: SidebarListDoctor[]
  }
  fromSelfSignUp?: boolean
}

const DoctorReviewerDashboard = (props: Props) => {
  const flash = useFlashContext()
  const muiStyles = useMemo(() => styles(flash), [flash])
  const muiClasses = useStyles(muiStyles)

  const [patientSearchQuery, setPatientSearchQuery] = useState("")
  const [
    actionableSignatureRequestsStatus,
    setActionableSignatureRequestsStatus,
  ] = useState<{ review: boolean; sign: boolean }>(
    props.actionableSignatureRequestsStatus
  )

  const [doctors, setDoctors] = useState(props.sidebarInformation.doctors)

  const notificationBadgeStatusChecker = useCallback(
    (doctorId: string) =>
      checkForActionableSignatureRequests(doctorId)
        .then((response) => {
          setActionableSignatureRequestsStatus(response)
          setDoctors((prevDoctors) => {
            prevDoctors.find(
              (doc) => doc.id === doctorId
            ).hasActionableSignatureRequests = Object.values(response).includes(
              true
            )
            return [...prevDoctors]
          })
        })
        .catch(handleError),
    []
  )

  return (
    <Switch>
      <Route
        path={BaseRoute}
        render={({ match, history }) => {
          const activeTab = (match.params.tab as Tab) || Tab.REVIEW
          const doctorId = match.params.doctorId
          const showFacilityPromo =
            activeTab === Tab.FACILITY_SIGN_UP &&
            props.currentUserEligibleForFacilitySignup

          return (
            <MaterialThemeProvider>
              <ToastProvider>
                <LeftSidebar
                  active={
                    activeTab === Tab.FACILITY_SIGN_UP
                      ? LeftSidebar.Options.Orders
                      : LeftSidebar.Options.SignatureRequests
                  }
                  sidebarInformation={{
                    ...props.sidebarInformation,
                    doctors: doctors,
                    currentDoctorId: doctorId,
                  }}
                  showFacilityDashboardNewPill={
                    props.currentUserEligibleForFacilitySignup
                  }
                  featureFlags={props.featureFlags}
                  history={history}
                  activeTab={activeTab}
                />
                <DoctorProvider
                  doctorId={doctorId}
                  initialDoctor={props.initialDoctor}
                  notificationBadgeStatusChecker={
                    notificationBadgeStatusChecker
                  }
                >
                  <FlashProvider optIn={props.optIn}>
                    <ReviewerAgreementWrapper>
                      <div>
                        <Alerts
                          fromSelfSignUp={props.fromSelfSignUp}
                          optIn={props.optIn}
                          reviewer={props.reviewer}
                        />
                        <div
                          className={
                            showFacilityPromo
                              ? muiClasses.promoContainer
                              : muiClasses.container
                          }
                        >
                          {showFacilityPromo ? (
                            <FacilitySignup
                              currentUserEmail={props.reviewer.email}
                              headingTitle="Start ordering with Parachute!"
                              redirectToFacilitySignupPage={
                                props.redirectToFacilitySignupPage
                              }
                            />
                          ) : (
                            <>
                              <div className={muiClasses.header}>
                                <Header
                                  activeTab={activeTab}
                                  actionableSignatureRequestsStatus={
                                    actionableSignatureRequestsStatus
                                  }
                                  clearPatientSearchQuery={() =>
                                    setPatientSearchQuery("")
                                  }
                                />
                              </div>
                              <Grid
                                container
                                alignItems="stretch"
                                classes={{ root: muiClasses.body }}
                                wrap="nowrap"
                              >
                                {activeTab === Tab.REVIEW && (
                                  <SignatureRequestReviewTab
                                    patientSearchQuery={patientSearchQuery}
                                    setPatientSearchQuery={
                                      setPatientSearchQuery
                                    }
                                    currentUserEmail={props.reviewer.email}
                                    currentUserEligibleForFacilitySignup={
                                      props.currentUserEligibleForFacilitySignup
                                    }
                                    redirectToFacilitySignupPage={
                                      props.redirectToFacilitySignupPage
                                    }
                                    actionableSignatureRequestsStatus={
                                      actionableSignatureRequestsStatus
                                    }
                                    notificationBadgeStatusChecker={(
                                      doctorId
                                    ) =>
                                      void notificationBadgeStatusChecker(
                                        doctorId
                                      )
                                    }
                                  />
                                )}
                                {activeTab === Tab.SIGN && (
                                  <Grid
                                    item
                                    xs
                                    data-testid="signature-request-sign-tab"
                                  >
                                    <SignatureRequestSignTab
                                      patientSearchQuery={patientSearchQuery}
                                      setPatientSearchQuery={
                                        setPatientSearchQuery
                                      }
                                      currentUserEmail={props.reviewer.email}
                                      currentUserEligibleForFacilitySignup={
                                        props.currentUserEligibleForFacilitySignup
                                      }
                                      redirectToFacilitySignupPage={
                                        props.redirectToFacilitySignupPage
                                      }
                                      actionableSignatureRequestsStatus={
                                        actionableSignatureRequestsStatus
                                      }
                                      notificationBadgeStatusChecker={(
                                        doctorId
                                      ) =>
                                        void notificationBadgeStatusChecker(
                                          doctorId
                                        )
                                      }
                                    />
                                  </Grid>
                                )}
                                {activeTab === Tab.DONE && (
                                  <Grid
                                    item
                                    xs
                                    data-testid="signature-request-done-tab"
                                  >
                                    <SignatureRequestDoneTab />
                                  </Grid>
                                )}
                              </Grid>
                            </>
                          )}
                        </div>
                      </div>
                    </ReviewerAgreementWrapper>
                  </FlashProvider>
                </DoctorProvider>
              </ToastProvider>
            </MaterialThemeProvider>
          )
        }}
      />
    </Switch>
  )
}

export default withBrowserRouter(DoctorReviewerDashboard, {})
