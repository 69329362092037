import React, { useState } from "react"
import { CanopyButton } from "@parachutehealth/canopy-button"

import * as styles from "./index.module.scss"
import { post } from "services/api"
import { navigate, reload } from "utilities/navigation"
import { createOrderUrl, orderUrl } from "../../../urls"
import { useCsrInboxState } from "../../../csrInboxStateContext"
import { useIsMutating, useQuery } from "@tanstack/react-query"
import { useErrorContext } from "../../../error-context"
import { InboxStateClinicalFacility } from "../FacilityData"

const useAllRequiredFieldsSetCheck = () => {
  const infinitySoThatThisWillNotRefetchUnlessSomeoneInvalidatesTheCacheManually = Infinity
  const { data: facilityData } = useQuery<InboxStateClinicalFacility>({
    queryKey: ["csrInboxState", "facility"],
    queryFn: () => ({}),
    staleTime: infinitySoThatThisWillNotRefetchUnlessSomeoneInvalidatesTheCacheManually,
  })
  return !!facilityData?.facility?.id
}

export const ActionsFooter = () => {
  const numberOfActiveMutations = useIsMutating()
  const csrInboxState = useCsrInboxState()
  const { setDisplayErrors } = useErrorContext()
  const allRequiredFieldsSet = useAllRequiredFieldsSetCheck()

  const [saving, setSaving] = useState(false)
  const onClick = () => {
    if (!allRequiredFieldsSet) {
      setDisplayErrors(true)
    } else {
      setSaving(true)
      post(
        createOrderUrl(csrInboxState.supplierOrganization.id, csrInboxState.id)
      )
        .then((response) => {
          setSaving(false)
          navigate(orderUrl(csrInboxState.supplier.id, response["data"]["id"]))
        })
        .catch(() => {
          setSaving(false)
          reload()
        })
    }
  }

  return (
    <div className={styles.actionsFooter}>
      <CanopyButton
        loading={saving}
        onClick={onClick}
        disabled={numberOfActiveMutations > 0}
      >
        Save and go to order
      </CanopyButton>
    </div>
  )
}

export default ActionsFooter
