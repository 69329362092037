import { Supplier, SupplierGrouping } from "../sharedTypes"
import React from "react"
import ViewMoreButton from "../ViewMoreButton"
import SupplierCards from "../SupplierCards"
import { Props as OtherSuppliersProps } from "../OtherSuppliersOnParachuteHeader"
import { Props as YourOrganizationsSuppliersProps } from "../YourOrganizationsSuppliersHeader"

interface Props {
  Header:
    | React.ComponentType<OtherSuppliersProps>
    | React.ComponentType<YourOrganizationsSuppliersProps>
  supplierGrouping: SupplierGrouping
  manageFulfillmentAgreements: boolean
  suppliers: Supplier[]
  suppliersCount: number
  hasActiveSuppliers: boolean
  loading: boolean
  fetchNextPage: () => void
  usesEnterpriseFeatures: boolean
}

const SupplierCardGrid: React.FC<Props> = ({
  Header,
  supplierGrouping,
  manageFulfillmentAgreements,
  suppliers,
  suppliersCount,
  hasActiveSuppliers,
  loading,
  fetchNextPage,
  usesEnterpriseFeatures,
}) => {
  const showViewMoreButton = suppliers.length < suppliersCount

  return (
    <>
      <Header
        suppliersCount={suppliersCount}
        hasActiveSuppliers={hasActiveSuppliers}
        manageFulfillmentAgreements={manageFulfillmentAgreements}
        usesEnterpriseFeatures={usesEnterpriseFeatures}
      />
      <SupplierCards
        suppliers={suppliers}
        isOtherSuppliersSection={supplierGrouping === SupplierGrouping.Other}
      />
      {showViewMoreButton && (
        <ViewMoreButton disabled={loading} onClick={fetchNextPage} />
      )}
    </>
  )
}

export default SupplierCardGrid
