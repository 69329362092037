// @ts-strict-ignore
import React from "react"
import {
  Context,
  EmrCachedPatients,
  HospicePatient,
  MasterPatient,
  RecentOrder,
} from "sharedTypes"
import InternalPatientProfile from "./components/InternalPatientProfile"
import { makeApi } from "./api"
import { handleError } from "utilities/error"
import { navigate } from "utilities/navigation"
import { makeUrlProvider } from "./urls"
import { FormData } from "./components/UpdatePatientHospiceForm"
import GlobalContext from "context/Global"
import { EventCategory, trackEvent } from "utilities/tracking"
import { employerTypeFromContext } from "utilities/url"
import { ApolloProvider } from "@apollo/client"
import { apolloClient } from "services/graphql/apolloClient"

type Props = {
  patientProfile: {
    masterPatient: MasterPatient
    emrPatient: boolean
    emrCachedPatients: EmrCachedPatients
    recentOrders: RecentOrder[]
    hospicePatient: HospicePatient
    hospiceEnabled: boolean
    showSuppliers: boolean
    permissions: {
      updateHospice: boolean
      createOrder: boolean
    }
    supplierSystemPatientLinkages: { supplierSystemPatientId: string }[]
  }
  context: Context
}

const PatientProfile: React.FC<Props> = (props: Props) => {
  const {
    masterPatient,
    emrPatient,
    emrCachedPatients,
    recentOrders,
    hospicePatient,
    hospiceEnabled,
    showSuppliers,
    permissions,
    supplierSystemPatientLinkages,
  } = props.patientProfile
  const { context } = props
  const currentEmployer = props.context.currentEmployer
  const urlEmployerType = employerTypeFromContext(currentEmployer.employerType)
  const api = makeApi(urlEmployerType, currentEmployer.employerId)
  const urls = makeUrlProvider(urlEmployerType, currentEmployer.employerId)
  const emrSession = context.emrSession
  const createDmeOrder = (patientId?: string, cachedEncounterId?: string) => {
    const clinicalFacilityId =
      window.parachute &&
      window.parachute.employerType === "ClinicalFacility" &&
      window.parachute.employerId

    clinicalFacilityId &&
      emrSession &&
      trackEvent(
        EventCategory.FHIRIntegration,
        "emr-session-create-new-order",
        clinicalFacilityId
      )

    return trackEvent(
      EventCategory.Activation,
      "patient-profile-create-new-order"
    )
      .then(() =>
        api.createDmeOrder(
          clinicalFacilityId,
          masterPatient.id,
          patientId,
          cachedEncounterId
        )
      )
      .then((response) => {
        if (!!response.data.dmeOrderPath) {
          navigate(response.data.dmeOrderPath)
        } else if (!!response.data.dmeOrderCreationStatusToken) {
          navigate(
            urls.showAsyncDmeOrderUrl(response.data.dmeOrderCreationStatusToken)
          )
        }
      })
      .catch(handleError)
  }

  const reorderDmeOrder = (dmeOrderId: string) => {
    return api
      .reorderDmeOrder(dmeOrderId)
      .then((response) => {
        if (!!response.data.dmeOrderPath) {
          navigate(response.data.dmeOrderPath)
        }
      })
      .catch(handleError)
  }

  const updateHospiceDates = (values: FormData) => {
    return api.updateHospiceDates(
      hospicePatient.id,
      values.hospiceEpisodeAdmitDate,
      values.hospiceEpisodeDischargeDate
    )
  }

  return (
    <GlobalContext.Provider value={props.context}>
      <ApolloProvider client={apolloClient}>
        <InternalPatientProfile
          context={context}
          masterPatient={masterPatient}
          emrPatient={emrPatient}
          emrCachedPatients={emrCachedPatients}
          hospicePatient={hospicePatient}
          hospiceEnabled={hospiceEnabled}
          recentOrders={recentOrders}
          createDmeOrder={createDmeOrder}
          reorderDmeOrder={reorderDmeOrder}
          showDmeOrderUrl={urls.showDmeOrderUrl}
          showSuppliers={showSuppliers}
          permissions={permissions}
          updateHospiceDates={updateHospiceDates}
          sendEmrResetPasswordEmail={api.sendEmrResetPasswordEmail}
          supplierSystemPatientLinkages={supplierSystemPatientLinkages}
        />
      </ApolloProvider>
    </GlobalContext.Provider>
  )
}

export default PatientProfile
