import React from "react"
import { CanopyForm } from "@parachutehealth/canopy-form"
import { FacilityData } from "./FacilityData"
import PatientData from "./PatientData"
import { Patient } from "../../sharedTypes"
import { ContactInformation, PatientAddress } from "./ContactInformation"
import ActionsFooter from "./ActionsFooter"
import { dataFieldWrapper } from "../../index.module.scss"

export const DataEntryColumn = (params: {
  patient: Patient
  patientAddress: PatientAddress
}) => {
  const { patient, patientAddress } = params
  return (
    <>
      <div className={dataFieldWrapper}>
        <CanopyForm>
          <PatientData patient={patient} />
          <FacilityData />
          <ContactInformation address={patientAddress} />
        </CanopyForm>
      </div>
      <ActionsFooter />
    </>
  )
}
