import React from "react"
import { GridColDef, GridRowData } from "@mui/x-data-grid-pro"
import { titleize } from "../../../../../../utilities/string"
import RowMenu from "../../../../../Cms/components/RowMenu"
import {
  canopyColorInteractiveLinkDefault,
  canopyColorTextDanger,
} from "@parachutehealth/canopy-tokens-color"
import {
  canopyTypographyBodySmallFontSize,
  canopyTypographyFontWeightBold,
} from "@parachutehealth/canopy-tokens-typography"

type ActionCallback = (row: GridRowData) => void

interface ActionCallbacks {
  editCallback: ActionCallback
  welcomeEmailCallback: ActionCallback
  passwordResetCallback: ActionCallback
  removeCallback: ActionCallback
}

type UsersGridColDefArgs = {
  showActionsColumn: boolean
} & ActionCallbacks

export const usersTableColumnDefinitions = ({
  editCallback,
  welcomeEmailCallback,
  passwordResetCallback,
  removeCallback,
  showActionsColumn,
}: UsersGridColDefArgs): GridColDef[] => [
  {
    field: "firstName",
    flex: 1,
    headerName: "First name",
  },
  {
    field: "lastName",
    flex: 1,
    headerName: "Last name",
  },
  {
    field: "email",
    flex: 1.5,
    headerName: "Email",
  },
  {
    field: "role",
    flex: 0.5,
    headerName: "Role",
    valueGetter: (params) => titleize(params.row.currentEmployment?.role || ""),
  },
  {
    field: "actions",
    hide: !showActionsColumn,
    flex: 0.5,
    minWidth: 100,
    headerName: " ",
    hideSortIcons: true,
    disableColumnMenu: true,
    sortable: false,
    filterable: false,
    align: "right",
    renderCell: (params) => (
      <>
        <RowMenu
          style={{
            color: canopyColorInteractiveLinkDefault,
            fontSize: canopyTypographyBodySmallFontSize,
            fontWeight: canopyTypographyFontWeightBold,
          }}
          row={params.row}
          actions={[
            { label: "Edit", onClick: () => editCallback(params.row) },
            ...(!params.row.samlUser
              ? [
                  {
                    label: "Resend welcome email",
                    onClick: () => welcomeEmailCallback(params.row),
                  },
                  {
                    label: "Send password reset email",
                    onClick: () => passwordResetCallback(params.row),
                  },
                ]
              : []),
            {
              label: <div style={{ color: canopyColorTextDanger }}>Remove</div>,
              onClick: () => removeCallback(params.row),
            },
          ]}
        />
      </>
    ),
  },
]
