import InfoIcon from "@material-ui/icons/Info"
import React from "react"
import {
  ClickAwayListener,
  Paper,
  Popper,
  PopperProps,
  Typography,
} from "@material-ui/core"
import * as tokens from "@parachutehealth/canopy-tokens-color"

export type InfoTooltipProps = {
  children: React.ReactElement
  className?: string
  popperProps?: PopperProps
}

export const InfoTooltip = (props: InfoTooltipProps) => {
  const { children, popperProps, className } = props
  const [open, setOpen] = React.useState<boolean>(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleInfoIconClick = (event) => {
    if (open) {
      handleClosePopper()
    } else {
      setAnchorEl(event.currentTarget)
      setOpen(!open)
    }
  }

  const handleClosePopper = () => {
    setOpen(false)
    setAnchorEl(null)
  }

  return (
    <>
      <ClickAwayListener onClickAway={handleClosePopper}>
        <div className={className}>
          <InfoIcon
            onClick={handleInfoIconClick}
            fontSize="small"
            data-testid="info-icon"
            htmlColor={tokens.canopyColorPrimitivesGray39}
          />
          <Popper
            open={open}
            anchorEl={anchorEl}
            placement="right"
            style={{ maxWidth: "400px" }}
            {...popperProps}
          >
            <Paper className="canopy-p-8x" elevation={3}>
              <Typography variant="body2">{children}</Typography>
            </Paper>
          </Popper>
        </div>
      </ClickAwayListener>
    </>
  )
}
