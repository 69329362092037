import {
  CatalogBrowseTab,
  CatalogSearchType,
  ConsignmentCloset,
  DmeOrder,
  Nullable,
} from "sharedTypes"
import React from "react"
import { CategoryWithSubcategories, PackageSku } from "../sharedTypes"
import SidebarAwareContainer from "./SideBarAwareContainer"
import SkuQuickAdd from "./SkuQuickAdd"
import PackageFilter from "./PackageFilter"
import QuickListButton from "./QuickListButton"
import MobileFilters from "./ProductFilters/MobileFilters"
import { useFeatureFlags } from "../../../../../components/FeatureFlagContext"

interface Props {
  availableCategories: CategoryWithSubcategories[]
  canFilterByFormulary: boolean
  canFilterByYourOrganizationsSuppliers: boolean
  catalogSearchType: CatalogSearchType
  categories: CategoryWithSubcategories[]
  categoryId: number
  combinedCatalogSearch: boolean
  consignmentClosetOnly: boolean
  consignmentClosets: ConsignmentCloset[]
  currentCatalogBrowseTab: CatalogBrowseTab
  defaultConsignmentClosetId: string
  defaultServiceAreaState: string
  dmeOrder: DmeOrder
  filterQuery: string
  formularyOnly: boolean
  formularyPriceEnabled: boolean
  searchPackagesWithSku(query: string): Promise<{ data: PackageSku[] }>
  selectedCategory: Nullable<number>
  selectCategory(n: number, isSubcategory?: boolean): void
  selectDefaultConsignmentCloset(id: string): Promise<void>
  selectPackageWithSku(s: PackageSku): void
  selectState: (state: string) => void
  serviceAreaState: string
  subcategoryId: number
  supplierIdParam: string
  toggleConsignmentClosetFilter(): void
  toggleFormularyFilter(): void
  toggleYourOrganizationsSuppliersFilter(): void
  updateFilterQuery(query: string): void
  yourOrganizationsSuppliersOnly: boolean
}

const SingleSearch: React.FC<Props> = ({
  availableCategories,
  canFilterByFormulary,
  canFilterByYourOrganizationsSuppliers,
  catalogSearchType,
  categories,
  categoryId,
  combinedCatalogSearch,
  consignmentClosetOnly,
  consignmentClosets,
  currentCatalogBrowseTab,
  defaultConsignmentClosetId,
  defaultServiceAreaState,
  dmeOrder,
  filterQuery,
  formularyOnly,
  formularyPriceEnabled,
  searchPackagesWithSku,
  selectCategory,
  selectedCategory,
  selectState,
  selectDefaultConsignmentCloset,
  selectPackageWithSku,
  serviceAreaState,
  subcategoryId,
  supplierIdParam,
  toggleConsignmentClosetFilter,
  toggleFormularyFilter,
  toggleYourOrganizationsSuppliersFilter,
  updateFilterQuery,
  yourOrganizationsSuppliersOnly,
}) => {
  const { isFeatureEnabled } = useFeatureFlags()

  return (
    <div className="canopy-mbe-8x">
      <SidebarAwareContainer role="searchLabel">
        <label>Search</label>
      </SidebarAwareContainer>
      {catalogSearchType === CatalogSearchType.SkuQuickAdd ? (
        <SkuQuickAdd
          searchPackagesWithSku={searchPackagesWithSku}
          selectPackageWithSku={selectPackageWithSku}
          formularyPriceEnabled={formularyPriceEnabled}
        />
      ) : catalogSearchType === CatalogSearchType.PackageFilter ? (
        <PackageFilter
          updateFilterQuery={updateFilterQuery}
          filterQuery={filterQuery}
        />
      ) : null}
      {isFeatureEnabled("userActivationMobileFilters") && (
        <SidebarAwareContainer role="mobileFilters">
          <MobileFilters
            availableCategories={availableCategories}
            canFilterByFormulary={canFilterByFormulary}
            canFilterByYourOrganizationsSuppliers={
              canFilterByYourOrganizationsSuppliers
            }
            categories={categories}
            categoryId={categoryId}
            combinedCatalogSearch={combinedCatalogSearch}
            consignmentClosetOnly={consignmentClosetOnly}
            consignmentClosets={consignmentClosets}
            currentCatalogBrowseTab={currentCatalogBrowseTab}
            defaultConsignmentClosetId={defaultConsignmentClosetId}
            defaultServiceAreaState={defaultServiceAreaState}
            formularyOnly={formularyOnly}
            manageFulfillmentAgreements={
              dmeOrder.permissions.manageFulfillmentAgreements
            }
            selectCategory={selectCategory}
            selectedCategory={selectedCategory}
            selectDefaultConsignmentCloset={selectDefaultConsignmentCloset}
            selectState={selectState}
            serviceAreaState={serviceAreaState}
            subcategoryId={subcategoryId}
            toggleConsignmentClosetFilter={toggleConsignmentClosetFilter}
            toggleFormularyFilter={toggleFormularyFilter}
            toggleYourOrganizationsSuppliersFilter={
              toggleYourOrganizationsSuppliersFilter
            }
            usesEnterpriseFeatures={
              dmeOrder.clinicalFacility.usesEnterpriseFeatures
            }
            yourOrganizationsSuppliersOnly={yourOrganizationsSuppliersOnly}
          />
        </SidebarAwareContainer>
      )}
      <QuickListButton
        canFilterByFormulary={canFilterByFormulary}
        supplierIdParam={supplierIdParam}
      />
    </div>
  )
}

export default SingleSearch
