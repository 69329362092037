import { employerPrefix, userPrefix } from "utilities/url"

export function newOrderUrl() {
  return `${employerPrefix()}/orders/new`
}

export function clinicalFacilityDashboardResultsUrl() {
  return `${employerPrefix()}/dashboard/results.json`
}

export function clinicalFacilitySubpageResultsUrl(subpage?: string) {
  return `${employerPrefix()}/dashboard/results.json/?subpage=${subpage}`
}

export function searchFollowersUrl() {
  return `${employerPrefix()}/dashboard/followers.json`
}

export function searchSuppliersUrl() {
  return `${employerPrefix()}/dashboard/suppliers.json`
}

export function searchDoctorsUrl() {
  return `${employerPrefix()}/dashboard/doctors.json`
}

export function updateUiPreferencesUrl() {
  return `${userPrefix()}/ui_preferences.json`
}

export function createAdditionalContactResponsesUrl() {
  return `${employerPrefix()}/additional_contact_responses`
}

export function incomingOrdersCountUrl() {
  return `${employerPrefix()}/dashboard/incoming_orders.json`
}
