// extracted by mini-css-extract-plugin
var _1 = "app-javascript-applications-SupplierOrganizationInboxDocument-index-module__column-container--rcUnU";
var _2 = "app-javascript-applications-SupplierOrganizationInboxDocument-index-module__data-entry-column--wqXtw";
var _3 = "app-javascript-applications-SupplierOrganizationInboxDocument-index-module__data-field-wrapper--VVPYN";
var _4 = "app-javascript-applications-SupplierOrganizationInboxDocument-index-module__document-browser-frame--Dsend";
var _5 = "app-javascript-applications-SupplierOrganizationInboxDocument-index-module__document-browser-injected-style--mEsbl";
var _6 = "app-javascript-applications-SupplierOrganizationInboxDocument-index-module__document-column--QX4pU";
var _7 = "app-javascript-applications-SupplierOrganizationInboxDocument-index-module__json-code--o1OzF";
var _8 = "app-javascript-applications-SupplierOrganizationInboxDocument-index-module__json-code-wrapper--HjG5c";
export { _1 as "columnContainer", _2 as "dataEntryColumn", _3 as "dataFieldWrapper", _4 as "documentBrowserFrame", _5 as "documentBrowserInjectedStyle", _6 as "documentColumn", _7 as "jsonCode", _8 as "jsonCodeWrapper" }
