import React, { useContext, useEffect } from "react"
import { Link, useParams } from "react-router-dom"
import { isNullOrUndefined } from "../../../../../../utilities/isNullOrUndefined"
import { Backdrop, CircularProgress } from "@material-ui/core"
import {
  destroyConsignmentClosetSupplierPackageConfiguration,
  getConsignmentCloset,
  addSupplierPackageConfiguration,
  getSupplierPackageConfigurations,
} from "../../../../api/consignmentClosets"
import {
  CatalogConsignmentCloset,
  CatalogSupplierPackageConfiguration,
  Category,
  NameAndId,
} from "../../../../types/sharedTypes"
import { isTest } from "../../../../../../utilities/environment"
import DataGridToolbar from "../../../../components/DataGridToolbar"
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro"
import ContentArea from "../../../../components/ContentArea"
import DropdownMenu from "../../../../components/DropdownMenu"
import renderCategories from "../../../../components/DataGrid/cells/renderCategories"
import classNames from "classnames"
import * as styles from "../ConsignmentClosetDetailsPage/index.module.scss"
import { CanopyButton } from "@parachutehealth/canopy-button"
import {
  consignmentClosetsUrl,
  consignmentClosetExportUrl,
  consignmentClosetDuplicateUrl,
  consignmentClosetsSupplierFilterUrl,
  consignmentClosetDuplicateSPCFormUrl,
} from "../../../../urls/consignmentClosets"
import NoRows from "../../../../components/DataGrid/NoRows"
import { NoticeContext } from "../../../../contexts/NoticeContext"
import ConfirmDialog from "../../../../components/ConfirmDialog"
import { useFeatureFlags } from "../../../../../../components/FeatureFlagContext"
import { isEmpty } from "lodash"
import FetchingCombobox from "applications/Cms/components/FetchingCombobox"
import { AxiosError } from "axios"
import { usePolicies } from "../../../../contexts/PoliciesContext"
import Breadcrumbs from "../../../../components/Breadcrumbs"
import EditConsignmentClosetDrawer from "../EditConsignmentClosetDrawer"

type InternalConsignmentClosetDetailPageProps = {
  consignmentCloset: CatalogConsignmentCloset
  onConsignmentClosetUpdate: (
    consignmentClosetId: string | number
  ) => Promise<void>
}
const InternalConsignmentClosetDetailPage: React.FC<InternalConsignmentClosetDetailPageProps> = (
  props: InternalConsignmentClosetDetailPageProps
): React.JSX.Element => {
  const [removeConfirmationOpen, setRemoveConfirmationOpen] = React.useState(
    false
  )

  const { consignmentCloset } = props

  const { isFeatureEnabled } = useFeatureFlags()
  const { hasPermission } = usePolicies()
  const canCreateCCSPC = hasPermission(
    "ConsignmentClosetSupplierPackageConfiguration",
    "create"
  )

  const canDestroyCCSPC = hasPermission(
    "ConsignmentClosetSupplierPackageConfiguration",
    "destroy"
  )

  const [
    selectedPackageConfiguration,
    setSelectedPackageConfiguration,
  ] = React.useState<CatalogSupplierPackageConfiguration | null>()

  const generatedColumns: GridColDef[] = React.useMemo(() => {
    return [
      {
        field: "packageName",
        flex: 1,
        headerName: "Package Name",
      },
      {
        field: "name",
        flex: 1,
        headerName: "Package Configuration Name",
        renderCell: (params) => {
          return (
            <>
              <a href={params.row?.url}>{params?.value?.toString()}</a>
            </>
          )
        },
      },
      {
        field: "categories",
        flex: 1,
        headerName: "Categories",
        minWidth: 200,
        renderCell: renderCategories,
        valueGetter: (params) =>
          (params?.value as Category[])
            ?.map((category) => category.name)
            .join(),
      },
      {
        field: "actions",
        flex: 1,
        headerName: "Actions",
        minWidth: 200,
        renderCell: (params) => {
          return (
            <>
              {canCreateCCSPC && (
                <Link
                  to={consignmentClosetDuplicateSPCFormUrl(
                    consignmentCloset.externalId,
                    params.row.externalId
                  )}
                >
                  Duplicate config
                </Link>
              )}

              {canCreateCCSPC && canDestroyCCSPC && (
                <b
                  role="presentation"
                  className="canopy-min-4x"
                  aria-hidden="true"
                >
                  |
                </b>
              )}

              {canDestroyCCSPC && (
                <a
                  role="button"
                  className="color-danger"
                  onClick={() => {
                    setSelectedPackageConfiguration(
                      params.row as CatalogSupplierPackageConfiguration
                    )
                    setRemoveConfirmationOpen(true)
                  }}
                >
                  Remove
                </a>
              )}
            </>
          )
        },
        sortable: false,
        filterable: false,
      },
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { showNotice } = useContext(NoticeContext)

  const removePackageConfiguration = async () => {
    const success: boolean = await destroyConsignmentClosetSupplierPackageConfiguration(
      consignmentCloset.id,
      selectedPackageConfiguration!.id
    )
    if (success) {
      await props.onConsignmentClosetUpdate(consignmentCloset.id)
      showNotice("The package configuration has been removed", "success")
    } else {
      showNotice(
        "An error occurred while removing this package configuration",
        "danger"
      )
    }
  }

  const closeRemovalConfirmation = (): void => {
    setRemoveConfirmationOpen(false)
    setSelectedPackageConfiguration(null)
  }

  const [
    selectedSupplierPackageConfigId,
    setSelectedSupplierPackageConfigId,
  ] = React.useState<number | null>(null)

  const addSupplierPackageConfig = async () => {
    if (selectedSupplierPackageConfigId) {
      try {
        const response = await addSupplierPackageConfiguration(
          consignmentCloset.id,
          selectedSupplierPackageConfigId
        )
        if (response.success) {
          await props.onConsignmentClosetUpdate(consignmentCloset.id)
          showNotice(
            `You've successfully added ${response.supplierPackageConfiguration.packageName} to this Closet.`,
            "success"
          )
        }
      } catch (error) {
        if (
          error instanceof AxiosError &&
          error.response?.data?.errors?.match(
            /Supplier Package Configuration already exists on this Consignment Closet/i
          )
        ) {
          showNotice(
            "Package could not be added. Check to see if the package is already in this closet.",
            "danger"
          )
        } else {
          showNotice(
            "An error occurred while adding this Supplier Package Configuration to this Closet",
            "danger"
          )
        }
      }
    }
  }

  const fetchSupplierPackageConfigurations = async (
    consignmentClosetId: string | number,
    searchString: string
  ): Promise<NameAndId[]> => {
    if (isEmpty(searchString.trim())) return []
    return await getSupplierPackageConfigurations(consignmentClosetId, {
      searchKeywords: searchString,
    })
  }

  const [editDrawerOpen, setEditDrawerOpen] = React.useState<boolean>(false)
  const closeDrawer = () => {
    setEditDrawerOpen(false)
  }

  const onEditSuccess = async (closet: CatalogConsignmentCloset) => {
    await props.onConsignmentClosetUpdate(consignmentCloset.id)
    closeDrawer()
    showNotice(`Successfully updated ${closet.name}`, "success")
  }

  return (
    <>
      <EditConsignmentClosetDrawer
        open={editDrawerOpen}
        onClose={closeDrawer}
        selectedConsignmentCloset={consignmentCloset}
        onSuccess={onEditSuccess}
      />

      <div className={classNames(styles.header)}>
        <Breadcrumbs>
          {[
            { label: "All Closets", to: consignmentClosetsUrl() },
            {
              label: consignmentCloset.supplierName,
              to: consignmentClosetsSupplierFilterUrl(
                consignmentCloset.supplierId
              ),
            },
            { label: consignmentCloset.name },
          ]}
        </Breadcrumbs>
        <h1 className="canopy-typography-heading-2xlarge canopy-mt-4x canopy-mbe-4x">
          {consignmentCloset.name}
        </h1>
        <div>
          <h4 className={classNames(styles.subheading, "canopy-mbe-1x")}>
            Facility
          </h4>
          <span className="canopy-typography-body-small canopy-typography-font-weight-bold">
            {consignmentCloset.clinicalFacilityName}
          </span>
        </div>
        <div>
          <h4 className={classNames(styles.subheading)}>Status</h4>
          <span className="canopy-typography-body-small canopy-typography-font-weight-bold">
            {consignmentCloset.active ? "Active" : "Not Active"}
          </span>
        </div>
      </div>
      <ContentArea>
        <div className={classNames(styles.actions)}>
          <DropdownMenu
            className="canopy-ml-4x"
            label="More Actions"
            id="actions-menu"
          >
            {[
              {
                label: "Export",
                href: consignmentClosetExportUrl(consignmentCloset.externalId),
              },
              {
                label: "Edit",
                onClick: () => setEditDrawerOpen(true),
              },
              {
                label: "Duplicate Closet",
                ifTrue: Boolean(
                  hasPermission("ConsignmentCloset", "create") &&
                    isFeatureEnabled("consignmentClosetDuplication")
                ),
                to: consignmentClosetDuplicateUrl(consignmentCloset.externalId),
                className: classNames(styles.duplicateButton),
              },
            ]}
          </DropdownMenu>
          <FetchingCombobox<NameAndId>
            className={classNames(styles.packageSelect)}
            id="suppierPackageConfigurationId"
            hiddenLabel={true}
            label="Package"
            size="small"
            placeholder="Select Package"
            onSelection={(newValue: NameAndId[]) => {
              setSelectedSupplierPackageConfigId(newValue[0]?.id || null)
            }}
            fetchItems={(search: string) =>
              fetchSupplierPackageConfigurations(
                consignmentCloset.externalId,
                search
              )
            }
            getOptionLabel={(option: NameAndId) => option.name}
          />
          <CanopyButton
            className="canopy-ml-4x"
            variant="primary"
            size="small"
            onClick={addSupplierPackageConfig}
          >
            Add Package
          </CanopyButton>
        </div>
        <DataGridPro
          className="borderless"
          rows={consignmentCloset?.supplierPackageConfigurations || []}
          autoHeight
          density="standard"
          columns={generatedColumns}
          pagination={true}
          pageSize={100}
          rowsPerPageOptions={[100]}
          getRowId={(row) => row.externalId}
          disableVirtualization={isTest()} // Needs to be true for tests to work but ideally false in production, esp. for higher row counts
          hideFooterSelectedRowCount
          components={{
            Toolbar: DataGridToolbar,
            NoRowsOverlay: NoRows,
          }}
          componentsProps={{
            toolbar: { filter: true },
            noRowsOverlay: {
              message:
                "There are currently no package configurations in this Consignment Closet",
            },
          }}
        />
        <ConfirmDialog
          title="Remove Package Configuration"
          message="Removing this Package Configuration will make it unavailable to order from this Facility's Closet."
          confirmButtonText="Confirm"
          cancelButtonText="Cancel"
          open={removeConfirmationOpen}
          onConfirm={() =>
            selectedPackageConfiguration && removePackageConfiguration()
          }
          onCancel={closeRemovalConfirmation}
          handleClose={closeRemovalConfirmation}
        />
      </ContentArea>
    </>
  )
}

const ConsignmentClosetDetailPage: React.FC = () => {
  const { consignmentClosetId } = useParams()

  const [
    catalogConsignmentCloset,
    setCatalogConsignmentCloset,
  ] = React.useState<CatalogConsignmentCloset | undefined>()

  const [loading, setLoading] = React.useState<boolean>(
    isNullOrUndefined(catalogConsignmentCloset)
  )

  const loadConsignmentCloset = async (
    consignmentClosetId: string | number
  ) => {
    await getConsignmentCloset(consignmentClosetId).then(
      (consignmentCloset) => {
        setCatalogConsignmentCloset({ ...consignmentCloset })
      }
    )
    setLoading(false)
  }

  useEffect(() => {
    void loadConsignmentCloset(consignmentClosetId)
  }, [consignmentClosetId])

  if (loading) {
    return (
      <Backdrop style={{ zIndex: 999 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  } else {
    return (
      <InternalConsignmentClosetDetailPage
        consignmentCloset={catalogConsignmentCloset!}
        onConsignmentClosetUpdate={loadConsignmentCloset}
      />
    )
  }
}

export default ConsignmentClosetDetailPage
