import React, { useState } from "react"
import AsyncSelect from "react-select/async"
import classNames from "classnames"
import { highlight } from "utilities/select"
import { useDebounce } from "hooks/useDebounce"
import { fetchSupplierLegalEntities } from "applications/AsyncSupplierLegalEntitySelect/api"
import { camelizeKeys } from "humps"
import cx from "classnames"
import { Option } from "components/form/Select"
import Link from "components/Link"

export type SupplierLegalEntity = {
  id: string
  searchId?: number
  name: string
}

type Props = {
  currentSupplierLegalEntity: {
    id: string
    search_id: number
    name: string
  }
  supplierId: number
  legalEntityAssignmentMethod: string
  editLegalEntityPath: string
  supplierLegalEntityControlledByServiceArea: boolean
}

type ServiceAreaSubLabelProps = {
  editLegalEntityPath: string
}

const ServiceAreaSubLabel = (props: ServiceAreaSubLabelProps) => {
  return (
    <div className="sub-label">
      To change the Supplier Legal Entity on this facility, update the&nbsp;
      <Link
        href={props.editLegalEntityPath}
        target="_blank"
        className="color-brand"
      >
        service area
      </Link>
      .
    </div>
  )
}

const AsyncSupplierLegalEntitySelect = (props: Props) => {
  const convertToOption = (obj?: SupplierLegalEntity) => {
    if (obj && obj.searchId) {
      return {
        value: obj.searchId,
        label: obj.name,
        ...obj,
      }
    } else {
      return {
        value: "",
        label: "Select...",
      }
    }
  }

  const ALL_SEARCH = ""
  const renderSuggestion = (suggestion, { query, isSelected }) => (
    <div className={classNames({ highlight: isSelected })}>
      {highlight(suggestion.label, query)}
    </div>
  )

  const [query, setQuery] = useState(ALL_SEARCH)
  const [labelClass, setLabelClass] = useState("")

  const debouncedSupplierLegalEntities = useDebounce(fetchSupplierLegalEntities)

  const fetchOptions = () =>
    debouncedSupplierLegalEntities(query).then(({ data }) =>
      data.supplierLegalEntities.map(convertToOption)
    )

  const handleChange = (option: Option, { action }) => {
    if (
      action === "clear" ||
      option.value !== props.currentSupplierLegalEntity.search_id
    ) {
      setLabelClass("")
    }
  }

  return (
    <div className="form-group">
      <label className="col-form-label">Supplier legal entity</label>
      {props.supplierLegalEntityControlledByServiceArea && (
        <ServiceAreaSubLabel editLegalEntityPath={props.editLegalEntityPath} />
      )}
      <AsyncSelect
        id="supplier_legal_entity_select"
        isDisabled={props.supplierLegalEntityControlledByServiceArea}
        isSearchable
        defaultOptions
        cacheOptions
        isClearable
        loadOptions={fetchOptions}
        onInputChange={(inputValue: string) => {
          setLabelClass("")
          setQuery(inputValue)
        }}
        onChange={(option: Option, action: { action: string }) =>
          handleChange(option, action)
        }
        defaultValue={convertToOption(
          camelizeKeys(props.currentSupplierLegalEntity)
        )}
        name="fulfillment_agreement[supplier_legal_entity_id]"
        placeholder="Select..."
        className={cx(
          labelClass,
          "select2-selection select2-selection--multiple"
        )}
        renderOption={renderSuggestion}
      />
    </div>
  )
}

export default AsyncSupplierLegalEntitySelect
