import { employerPrefix } from "utilities/url"

export function ordersUrl(): string {
  return employerPrefix()
}

export function documentsUrl(): string {
  return `${employerPrefix()}/inbox`
}

export function authorizationsUrl(): string {
  return `${employerPrefix()}/authorizations`
}
