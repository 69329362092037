import Browsing from "../Browsing/Browsing"
import React from "react"
import { DmeOrder, CatalogBrowseTab } from "sharedTypes"
import { match } from "react-router-dom"

interface Props {
  dmeOrder: DmeOrder
  match: match<any>
  currentTab: CatalogBrowseTab
  yourOrganizationsSuppliersOnly: boolean
  toggleYourOrganizationsSuppliersOnly: () => void
}

const SearchByProductTab = ({
  dmeOrder,
  match,
  currentTab,
  yourOrganizationsSuppliersOnly,
  toggleYourOrganizationsSuppliersOnly,
}: Props) => {
  const dmeOrderSupplier = dmeOrder.supplier
  const matchSupplierId = (match): string | undefined => {
    if (dmeOrderSupplier) {
      return dmeOrderSupplier.externalId
    }
    if (match.params.supplierId) {
      return match.params.supplierId
    }
  }

  const supplierId = matchSupplierId(match)

  return (
    <Browsing
      dmeOrder={dmeOrder}
      supplierId={supplierId}
      currentCatalogBrowseTab={currentTab}
      yourOrganizationsSuppliersOnly={yourOrganizationsSuppliersOnly}
      toggleYourOrganizationsSuppliersOnly={
        toggleYourOrganizationsSuppliersOnly
      }
    />
  )
}

export default SearchByProductTab
