import React from "react"
import { Form, Select } from "components/form"
import { SearchPrompt, SearchableFields } from "./SearchPrompt"
import PatientSuggestion from "./Suggestion/Patient"
import EmptySuggestion from "./Suggestion/Empty"
import NewOrderSuggestion from "./Suggestion/NewPatient"
import { Patient } from "sharedTypes"
import { getQuery } from "../utilities/query"

interface Props {
  searchPatients(query: string): Promise<Patient[]>
  onPatientSelect(patientId: string): void
  onNewPatientSelect(query: string): void
  newPatientShortcut: boolean
  searchableFields: SearchableFields
  clearOnSelect: boolean
  debounceTimeInMilliseconds?: number
}

const MIN_LENGTH = 3
const NEW_OPTION_VALUE = "NEW_OPTION"

function InternalPatientSelect({
  searchPatients,
  onPatientSelect,
  onNewPatientSelect,
  newPatientShortcut,
  clearOnSelect,
  debounceTimeInMilliseconds,
  searchableFields,
}: Props) {
  const convertToOption = (patient: Patient) => ({
    value: patient.id,
    label: `${patient.firstName} ${patient.lastName}`,
    ...patient,
  })

  const newOption = (query: string) => ({
    value: NEW_OPTION_VALUE,
    label: query,
    query: query,
  })

  const fetchOptions = (query) =>
    searchPatients(query).then((patients) => {
      const patientResults = patients.map(convertToOption)
      return newPatientShortcut
        ? [...patientResults, newOption(query)]
        : patientResults
    })

  const renderOption = (patient, { query, isFocused }) => {
    if (patient.value === NEW_OPTION_VALUE) {
      return <NewOrderSuggestion />
    }
    return (
      <PatientSuggestion
        patient={patient}
        query={query}
        isHighlighted={isFocused}
      />
    )
  }

  const onChange = (value: string, option: { query: string | undefined }) => {
    if (value === NEW_OPTION_VALUE) {
      return onNewPatientSelect(getQuery(option.query))
    }
    return onPatientSelect(value)
  }

  return (
    <Form>
      <Select
        clearOnSelect={clearOnSelect}
        name="patient-select"
        placeholder="Search Patients"
        unwrapped
        small
        fetchOptions={fetchOptions}
        debounceOptions={{
          timeInMilliseconds: debounceTimeInMilliseconds,
          leading: false,
          memoDependencies: [fetchOptions],
        }}
        minLength={MIN_LENGTH}
        onChange={onChange}
        isSearchable
        maxMenuHeight={480}
        renderOption={renderOption}
        renderEmpty={(value) =>
          value && value.length >= MIN_LENGTH ? (
            newPatientShortcut ? undefined : (
              <EmptySuggestion />
            )
          ) : (
            <SearchPrompt
              minlength={MIN_LENGTH}
              searchableFields={searchableFields}
            />
          )
        }
      />
    </Form>
  )
}

export default InternalPatientSelect
