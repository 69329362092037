import React from "react"
import { searchIcd10Codes } from "./api"
import InternalIcd10Codes from "./InternalIcd10Codes"
import { DiagnosisHook } from "./hooks/useDiagnosis"

type Props = {
  className: string
  diagnosisHook: DiagnosisHook
  label: string
  triggerDiagnosisSelectionAlert?: {
    showAlert: boolean
    setShowAlert: (triggerAlert: boolean) => void
  }
}

export default function Icd10Codes(props: Props) {
  const {
    className,
    diagnosisHook,
    label,
    triggerDiagnosisSelectionAlert,
  } = props
  const { addIcd10Code, diagnosis, removeIcd10Code } = diagnosisHook
  return (
    <InternalIcd10Codes
      add={addIcd10Code}
      className={className}
      remove={removeIcd10Code}
      diagnosis={diagnosis}
      label={label}
      fetch={searchIcd10Codes}
      triggerDiagnosisSelectionAlert={triggerDiagnosisSelectionAlert}
    />
  )
}
