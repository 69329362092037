import { get, post } from "services/api"
import * as urls from "applications/Workflow/urls"
import { EmploymentMention } from "sharedTypes"

enum CommentType {
  Internal = "internal",
  Supplier = "supplier",
  Global = "global",
}

export function getActivityData() {
  return get(urls.sidebarActivityUrl())
}

export function getEmploymentMentions(
  term: string,
  commentType: CommentType
): Promise<EmploymentMention[]> {
  return get(urls.employmentMentionsUrl(), {
    term,
    commentType: commentType,
  }).then(({ data }) => data)
}

export function createComment(data) {
  return post(urls.commentsUrl(), data)
}

export function createInternalComment(data) {
  return post(urls.internalCommentsUrl(), data)
}

export function createSupplierComment(data) {
  return post(urls.supplierCommentsUrl(), data)
}

export function getActiveUsers() {
  return get(urls.activeUsersUrl())
}
