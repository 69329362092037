import React, { useState } from "react"
import Button from "components/form/Button"

type Props = {
  onSubmit(): Promise<void>
}

export default (props: Props) => {
  const [isSubmitting, setSubmitting] = useState(false)
  const { onSubmit } = props

  const handleSubmit = (event) => {
    event.stopPropagation()
    event.preventDefault()
    setSubmitting(true)
    onSubmit()
  }

  return (
    <Button
      type="submit"
      className="btn-sm btn-primary-o"
      loading={isSubmitting}
      disabled={isSubmitting}
      onClick={handleSubmit}
    >
      Re-Order
    </Button>
  )
}
