import { post, put } from "services/api"
import {
  patientPortalMessageTemplatesUrl,
  patientPortalMessageTemplateUrl,
} from "./urls"

import { build as buildEndpoint } from "services/patientPortal"

import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"

export const useSupplierSettingsQuery = (supplierId: string) => {
  return useQuery({
    queryKey: ["supplierSettings", supplierId],
    queryFn: () =>
      buildEndpoint()
        .fetchSupplier(supplierId)
        .then(({ data }) => data),
  })
}

export const useUpdateAutomatedWelcomeMessage = (supplierId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (template: string) => {
      return buildEndpoint().updateSupplier(supplierId, template)
    },
    onSuccess: ({ data }) => {
      queryClient.setQueryData(["supplierSettings", supplierId], data)
    },
  })
}

export const createPatientPortalMessageTemplate = (messageTemplate) =>
  post(patientPortalMessageTemplatesUrl(), { messageTemplate })
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response.data))

export const updatePatientPortalMessageTemplate = (messageTemplateId) => (
  messageTemplate
) =>
  put(patientPortalMessageTemplateUrl(messageTemplateId), { messageTemplate })
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response.data))
