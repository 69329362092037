import { useState } from "react"
import { DataGridOptions, DataGridPageArgs } from "../../sharedTypes"
import { GridSortModel } from "@mui/x-data-grid-pro"
import isEqual from "lodash.isequal"
import { PageInfo } from "../../graphql/__generated__/graphql"

export const useGraphqDataGrid = (
  refetch: any,
  paginate: any,
  pageInfo?: PageInfo,
  pageSize: number = 50,
  initialOptions?: Partial<DataGridOptions>
) => {
  const [gridOptions, setGridOptions] = useState<DataGridOptions>({
    page: 0,
    ...initialOptions,
  })

  const handleSorting = async (sortModel: GridSortModel) => {
    if (!isEqual(sortModel, gridOptions.sort)) {
      setGridOptions(() => ({
        page: 0,
        sort: sortModel,
      }))

      await refetch({
        sort: sortModel.length ? sortModel[0].field : null,
        sortDirection: sortModel.length
          ? sortModel[0].sort?.toUpperCase()
          : null,
      })
    }
  }

  const handlePagination = async (nextPage: number) => {
    if (gridOptions.page > 0 && !pageInfo?.hasPreviousPage) {
      setGridOptions({
        pageArgs: undefined,
        page: 0,
      })
      return
    }
    const pagingForward = nextPage > gridOptions.page
    const direction = pagingForward
      ? { after: pageInfo?.endCursor }
      : { before: pageInfo?.startCursor }
    const adjacentSelection = pagingForward ? "first" : "last"
    const fetchArgs = {
      ...direction,
      [adjacentSelection]: pageSize,
    } as DataGridPageArgs

    setGridOptions((prev) => ({
      ...prev,
      pageArgs: fetchArgs,
      page: nextPage,
    }))
    await paginate({ variables: fetchArgs })
  }

  return {
    gridOptions,
    handlePagination,
    handleSorting,
  }
}
