import { post } from "services/api"
import { loginUrl } from "./urls"

export const login = (
  loginParams: {
    login: string
    password: string
  },
  redirectTo: string
): Promise<{
  redirectTo?: string
}> => {
  return post(loginUrl(), { user: loginParams, redirectTo })
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response.data))
}
