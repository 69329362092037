import React, { ReactElement, useContext } from "react"
import GlobalContext from "context/Global"
import SignOutButton from "./SignOutButton"
import HomeLink from "./HomeLink"
import PushbackButtons from "./PushbackButtons"
import IncomingOrdersLink from "applications/Workflow/containers/Navigation/components/TopNavigation/TopLeftNavButton/IncomingOrdersLink/IncomingOrdersLink"

export default function TopLeftNavLink(): ReactElement {
  const {
    dmeOrderSpecific,
    emrPatientContext,
    nonprodHeaderDetails,
    showJoinFacilityButton,
    currentEmployer,
  } = useContext(GlobalContext)

  if (document.cookie.includes("incoming_orders_workflow_back_path")) {
    return <IncomingOrdersLink nonprodHeaderDetails={nonprodHeaderDetails} />
  }
  if (dmeOrderSpecific && emrPatientContext) {
    return <SignOutButton />
  } else if (dmeOrderSpecific && !emrPatientContext) {
    return (
      <PushbackButtons
        showJoinFacilityButton={showJoinFacilityButton}
        currentEmployer={currentEmployer}
      />
    )
  } else if (emrPatientContext) {
    return (
      <HomeLink
        label="Patient Profile"
        nonprodHeaderDetails={nonprodHeaderDetails}
      />
    )
  } else {
    return (
      <HomeLink label="Dashboard" nonprodHeaderDetails={nonprodHeaderDetails} />
    )
  }
}
