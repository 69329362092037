export function showSupplierSelector(
  selectedSupplier,
  availableSuppliers,
  fulfillable
) {
  if (!fulfillable) {
    return true
  } else if (!selectedSupplier && availableSuppliers.length > 1) {
    return true
  } else {
    return false
  }
}
