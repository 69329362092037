import React, { useCallback, useEffect, useState } from "react"
import { ClinicalFacility } from "sharedTypes"
import { useDebounce } from "hooks/useDebounce"
import DashboardHeader from "../DashboardHeader"
import DashboardColumn from "../DashboardColumn"
import LeftSidebar from "components/ClinicalDashboard/LeftSidebar"
import { useFeatureFlags } from "components/FeatureFlagContext"
import {
  SidebarListDoctor,
  SidebarListFacility,
} from "components/ClinicalDashboard/sharedTypes"
import { useEffectThatWontRunOnMount } from "hooks/useEffectThatWontRunOnMount"
import { AxiosPromise } from "axios"
import {
  canopyColorInteractiveLinkDefault,
  canopyColorTextSecondary,
} from "@parachutehealth/canopy-tokens-color"
import { CanopyButton } from "@parachutehealth/canopy-button"
import Icon from "components/Icon"

type Props = {
  clinicalFacility: ClinicalFacility
  limit: number
  unreadEventCounts: object
  getClinicalFacilityDashboardResults(values?): Promise<any>
  sidebarInformation: {
    incomingOrdersEnabled: boolean
    isReviewer: boolean
    lastAccessedFacilityEmployerId?: string
    doctors?: SidebarListDoctor[]
    facilities?: SidebarListFacility[]
    lastAccessedDoctorEmployerId?: string
  }
  sidebarExpanded: boolean
  updateSidebarExpandedPreference: (sidebarExpanded: boolean) => AxiosPromise
}

const InternalClinicalFacilityDashboardSubpage: React.FC<Props> = ({
  clinicalFacility,
  limit,
  getClinicalFacilityDashboardResults,
  sidebarInformation,
  sidebarExpanded,
  updateSidebarExpandedPreference,
}: Props) => {
  const [loading, setLoading] = useState(true)
  const [inProgressOrders, setInProgressOrders] = useState([])
  const [submittedOrders, setSubmittedOrders] = useState([])
  const [doneOrders, setDoneOrders] = useState([])
  const [unreadEventCounts, setUnreadEventCounts] = useState({})
  const { isFeatureEnabled } = useFeatureFlags()
  const incomingOrdersEnabledSetting = isFeatureEnabled(
    "incomingOrdersEnabledSetting"
  )
  const facilityIncomingOrders = isFeatureEnabled("facilityIncomingOrders")

  const newPillIncomingOrders = isFeatureEnabled("newPillIncomingOrders")

  const [initialLeftSidebarExpanded, setInitialLeftSidebarExpanded] = useState<
    boolean
  >(sidebarExpanded)

  const debouncedGetClinicalFacilityDashboardResults = useDebounce(
    getClinicalFacilityDashboardResults
  )
  const fetch = useCallback(
    () =>
      debouncedGetClinicalFacilityDashboardResults()
        .then((results) => {
          setInProgressOrders(results.inProgressOrders)
          setSubmittedOrders(results.submittedOrders)
          setDoneOrders(results.doneOrders)
          setUnreadEventCounts(results.unreadEventCounts)
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
        }),
    [debouncedGetClinicalFacilityDashboardResults]
  )

  useEffect(() => {
    fetch()
  }, [fetch])

  function onToggleExpand() {
    setInitialLeftSidebarExpanded(!initialLeftSidebarExpanded)
  }

  useEffectThatWontRunOnMount(() => {
    updateSidebarExpandedPreference(initialLeftSidebarExpanded)
  }, [initialLeftSidebarExpanded])

  return (
    <div className="position-relative">
      <LeftSidebar
        active={LeftSidebar.Options.Orders}
        sidebarInformation={sidebarInformation}
        showSignatureRequestsDashboardNewPill={false}
        featureFlags={{
          incomingOrdersEnabledSetting: !!incomingOrdersEnabledSetting,
          facilityIncomingOrders: !!facilityIncomingOrders,
          newPillIncomingOrders: !!newPillIncomingOrders,
        }}
        onToggleExpand={onToggleExpand}
        expandLeftSidebar={initialLeftSidebarExpanded}
      />
      <div className="row mt-n3">
        <div className="col-12 canopy-mbs-12x">
          <>
            <CanopyButton
              as="a"
              accessibleText="navigate back"
              className="canopy-pie-4x"
              href={`${window.location.origin}/u/f/${clinicalFacility.externalId}`}
              iconStart="arrow-left"
              size="small"
              style={{
                color: canopyColorInteractiveLinkDefault,
              }}
              variant="tertiary"
            >
              {`View all orders at ${clinicalFacility.name}`}
            </CanopyButton>
            <p
              className="canopy-typography-heading-2xlarge canopy-mt-16x"
              aria-label="page title"
            >
              Incoming orders
            </p>
            <h3
              className="canopy-typography-body-medium canopy-mbe-0 canopy-pb-8x"
              aria-label="subtitle"
              style={{ color: canopyColorTextSecondary }}
            >
              Orders sent from suppliers that require your attention.
            </h3>
          </>
          {loading ? (
            <div className="text-center">
              <Icon type="spinner" spin />
            </div>
          ) : (
            <div className="js-order-columns">
              <div className="row">
                <div className="col-lg-4 col-12">
                  <DashboardHeader
                    title="Orders in Progress"
                    limit={limit}
                    count={inProgressOrders.length}
                  />
                  <DashboardColumn
                    dmeOrders={inProgressOrders}
                    clinicalFacility={clinicalFacility}
                    unreadEventCounts={unreadEventCounts}
                    limit={limit}
                    showProgress
                  />
                </div>
                <div className="col-lg-4 col-12">
                  <DashboardHeader
                    title="Sent to Supplier"
                    limit={limit}
                    count={submittedOrders.length}
                  />
                  <DashboardColumn
                    dmeOrders={submittedOrders}
                    clinicalFacility={clinicalFacility}
                    unreadEventCounts={unreadEventCounts}
                    limit={limit}
                    showSwatch
                    showMissingDocument
                  />
                </div>
                <div className="col-lg-4 col-12">
                  <DashboardHeader
                    title="Accepted by Supplier"
                    limit={limit}
                    count={doneOrders.length}
                  />
                  <DashboardColumn
                    dmeOrders={doneOrders}
                    clinicalFacility={clinicalFacility}
                    unreadEventCounts={unreadEventCounts}
                    limit={limit}
                    showSwatch
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default InternalClinicalFacilityDashboardSubpage
