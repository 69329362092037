// @ts-strict-ignore
import React from "react"
import Sidebar from "components/LeftSidebar"
import { authorizationsUrl, ordersUrl, documentsUrl } from "./urls"
import { Inbox, LocalShippingOutlined } from "@material-ui/icons"
import Approval from "./ApprovalIcon"

interface Counts {
  orders: number
  documents: number
  authorizations: number
}

interface Props {
  active: Options
  counts: Counts
  authorizationEnabled?: boolean
}

enum Options {
  Orders = "Orders",
  Documents = "Documents",
  Authorizations = "Authorizations",
}

function LeftSidebar({ active, counts, authorizationEnabled }: Props) {
  return (
    <Sidebar
      options={[
        {
          icon: LocalShippingOutlined,
          text: Options.Orders,
          count: counts.orders,
          href: ordersUrl(),
          active: active === Options.Orders,
          tooltip: "Orders Dashboard",
        },
        {
          icon: Inbox,
          text: Options.Documents,
          count: counts.documents,
          href: documentsUrl(),
          active: active === Options.Documents,
          tooltip: "Inbound Documents",
          badgeEnabled: counts.documents > 0,
        },
        authorizationEnabled && {
          icon: Approval,
          text: Options.Authorizations,
          count: counts.authorizations,
          href: authorizationsUrl(),
          active: active === Options.Authorizations,
          tooltip: "Authorizations",
        },
      ].filter(Boolean)}
      fixed
    />
  )
}

LeftSidebar.Options = Options

export default LeftSidebar
