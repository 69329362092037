// @team @facilitators
import { get } from "services/api"
import { availableSuppliersUrl } from "../../../../urls"
import { AvailableSuppliers } from "./sharedTypes"

export function fetchSuppliers(
  catalogPackageId: number,
  yourOrganizationsSuppliersOnly: boolean
): Promise<AvailableSuppliers> {
  return get(availableSuppliersUrl(), {
    catalogPackageId: catalogPackageId,
    yourOrganizationsSuppliersOnly: yourOrganizationsSuppliersOnly,
  }).then(({ data }) => data)
}
