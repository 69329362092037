import React, { useState } from "react"
import { CanopyTextInputField } from "@parachutehealth/canopy-text-input-field"
import { states } from "utilities/address"
import { CanopySelectField } from "@parachutehealth/canopy-select-field"
import * as styles from "./index.module.scss"
import { useCsrInboxState } from "../../../csrInboxStateContext"
import { updatePatientAddressUrl } from "../../../urls"
import { put } from "services/api"
import { AxiosPromise } from "axios"

export type PatientAddress = {
  line1?: string
  line2?: string
  city?: string
  state?: string
  zip?: string
}

const StateSelect = ({
  value,
  onChange,
}: {
  value?: string
  onChange: (value: string) => void
}) => {
  const stateOptions = states.map((state) => ({
    label: state[1],
    value: state[1],
  }))
  const options = [
    { label: "Enter state", value: "item_not_selected", disabled: true },
    ...stateOptions,
  ]

  const defaultValue = stateOptions.some((o) => o.value === value)
    ? value
    : "item_not_selected"
  return (
    <CanopySelectField
      className={styles.state}
      label="State"
      defaultValue={defaultValue}
      options={options}
      onChange={(e) => onChange(e.target.value)}
    />
  )
}

const TextField = ({
  address,
  onChange,
  label,
  fieldName,
  placeholder,
  className,
}: {
  address: PatientAddress
  onChange(patientAddress: Partial<PatientAddress>): AxiosPromise
  fieldName: keyof PatientAddress
  label: string
  placeholder: string
  className?: string
}) => {
  const [error, setError] = useState<string | undefined>(undefined)
  const [saving, setSaving] = useState<boolean>(false)
  const onBlur = (fieldValue: string) => {
    setSaving(true)
    onChange({ [fieldName]: fieldValue })
      .then(() => {
        setError(undefined)
      })
      .catch(({ response }) => {
        const errors = response?.data?.errors || {}
        const error = errors[fieldName]
        setError(error ? error[0] : "Something went wrong")
      })
      .finally(() => setSaving(false))
  }
  return (
    <CanopyTextInputField
      label={label}
      defaultValue={address[fieldName]}
      onBlur={(e) => onBlur(e.target.value)}
      feedbackMessage={error}
      placeholder={placeholder}
      className={className}
      readOnly={saving}
    />
  )
}
const updatePatientAddress = (
  supplierOrganizationId: string,
  csrInboxStateId: string,
  patientAddress: Partial<PatientAddress>
) =>
  // Change in story below to react-query:
  // https://parachutehealth.atlassian.net/browse/WF-26557
  put(updatePatientAddressUrl(supplierOrganizationId, csrInboxStateId), {
    patientAddress,
  })

export const ContactInformation = ({
  address,
}: {
  address: PatientAddress
}) => {
  const csrInboxState = useCsrInboxState()

  const onChange = (patientAddress: Partial<PatientAddress>) =>
    updatePatientAddress(
      csrInboxState.supplierOrganization.id,
      csrInboxState.id,
      patientAddress
    )

  return (
    <>
      <h2 className="canopy-typography-heading-xlarge">Patient info</h2>
      <h3 className="canopy-typography-heading-large">Contact info</h3>
      <div className={styles.addressLineContainer}>
        <TextField
          address={address}
          onChange={onChange}
          label="Address - Line 1"
          fieldName="line1"
          placeholder="Enter address line 1"
        />
        <TextField
          address={address}
          onChange={onChange}
          label="Address - Line 2"
          placeholder="Enter address line 2"
          fieldName="line2"
        />
      </div>
      <div className={styles.cityStateZiplineContainer}>
        <TextField
          address={address}
          onChange={onChange}
          fieldName="city"
          className={styles.city}
          label="City"
          placeholder="Enter city"
        />
        <StateSelect
          value={address.state}
          onChange={(value) => onChange({ state: value })}
        />
        <TextField
          address={address}
          onChange={onChange}
          fieldName="zip"
          className={styles.zip}
          label="Zip"
          placeholder="Enter zip"
        />
      </div>
    </>
  )
}
