import React from "react"
import { Field } from "formik"
import { SupplierChatTemplate } from "../types"
import { EventCategory, trackEvent } from "utilities/tracking"
import * as styles from "./ChatTemplates.module.scss"

const SUPPLIER_CHAT_TEMPLATE_CLICK = "supplier-chat-template-click"

type Props = {
  name: string
  id?: string
  templates: SupplierChatTemplate[]
  className?: string
  onClick(SupplierChatTemplate): void
}

const ChatTemplates: React.FC<Props> = ({
  id,
  name,
  templates,
  className,
  onClick,
}) => {
  const renderRadioButtons = ({ field }) =>
    templates.map((template) => renderRadio(template, { field }))

  const renderRadio = (template, { field }) => {
    const inputId = `${id || name}-${template.id}`

    return (
      <div key={inputId} className={styles.template}>
        <input
          type="radio"
          id={inputId}
          name={name}
          className={styles.hiddenRadio}
          value={template.id}
          checked={field.value === template.id}
          onClick={(_event) => onClick(template)}
          onChange={(_) => {}}
        />
        <label
          htmlFor={inputId}
          className={styles.templateLabel}
          onClick={() =>
            trackEvent(
              EventCategory.PatientPortalChat,
              SUPPLIER_CHAT_TEMPLATE_CLICK,
              template.id
            )
          }
        >
          {template.name}
        </label>
      </div>
    )
  }

  const renderField = (render) => {
    return <Field name={name}>{render}</Field>
  }

  return (
    <div className={className}>
      <div className={styles.templateContainer}>
        {renderField((props) => renderRadioButtons(props))}
      </div>
    </div>
  )
}

export default ChatTemplates
