import React, { ReactElement } from "react"
import { Diagnosis, Icd10Code as Icd10CodeType } from "sharedTypes"
import Icd10CodeSuggestion from "./Suggestion"
import Icd10Code from "./Icd10Code"
import Note from "./Note"
import Alert from "components/Alert"
import { groupByCategory } from "./utilities/codes"
import { MAX_DIAGNOSES } from "./utilities/validation"
import Select, { omitOptionProps } from "components/form/Select"
import { Form } from "components/form"
import NoResults from "components/form/Select/Suggestion/NoResults"
import * as styles from "./index.module.scss"
import cx from "classnames"

type Props = {
  add(code: Icd10CodeType): void
  className: string
  fetch({
    value,
  }: {
    value: string
  }): Promise<{ data: { codes: Icd10CodeType[] } }>
  remove(code: Icd10CodeType): void
  diagnosis: Diagnosis
  label: string
  triggerDiagnosisSelectionAlert?: {
    showAlert: boolean
    setShowAlert: (triggerAlert: boolean) => void
  }
}

export default function InternalIcd10Codes(props: Props): ReactElement {
  const {
    add,
    className,
    fetch,
    remove,
    diagnosis,
    label,
    triggerDiagnosisSelectionAlert,
  } = props
  const { showAlert, setShowAlert = () => {} } =
    triggerDiagnosisSelectionAlert || {}
  const { icd10Codes, warnings: diagnosisWarnings } = diagnosis
  const isIcd10CodeSelected = (icd10Code) =>
    !!icd10Codes.find(({ code }) => icd10Code.code === code)
  const icd10Select = () => (
    <>
      <>
        <p className="color-dark-gray">
          Add diagnosis codes for products in your order
        </p>
        {diagnosisWarnings.length > 0 && (
          <div className="canopy-mbe-8x">
            {diagnosisWarnings.map((item) => (
              <Note key={item.name} item={item} />
            ))}
          </div>
        )}
      </>
      <label className="col-form-label" htmlFor="icd10-codes-select">
        {label}
      </label>
      {icd10Codes.length < MAX_DIAGNOSES ? select() : maxDiagnosisAlert}
      {icd10Codes.map((icd10Code) => (
        <Icd10Code key={icd10Code.code} icd10Code={icd10Code} remove={remove} />
      ))}
    </>
  )

  const select = () => (
    <Form className={cx(showAlert && styles.diagnosesSelect, "wide-fixed-row")}>
      <Select
        name="icd10-codes-select"
        placeholder="Search and select by ICD-10 or description"
        fetchOptions={(value) =>
          fetch({ value }).then((response) =>
            groupByCategory(response.data.codes)
          )
        }
        isSearchable
        openMenuOnFocus
        onFocus={() => {
          setShowAlert(false)
        }}
        onChange={(value, option) => {
          const icd10Code = omitOptionProps(option)
          if (isIcd10CodeSelected(icd10Code)) {
            remove(icd10Code)
          } else {
            add(icd10Code)
          }
          setShowAlert(false)
        }}
        clearOnSelect
        minLength={0}
        blurInputOnChange
        renderOption={(icd10Code, { query, isFocused }) => (
          <Icd10CodeSuggestion
            icd10Code={icd10Code}
            query={query}
            isHighlighted={isFocused}
            checked={isIcd10CodeSelected(icd10Code)}
            deny={icd10Code.deny}
            allow={icd10Code.allow}
          />
        )}
        renderEmpty={(query) =>
          query.length && <NoResults prefix="No matches for " query={query} />
        }
      />
      {showAlert && (
        <div className="color-danger canopy-mbe-4x">
          Relevant diagnosis codes needed
        </div>
      )}
    </Form>
  )

  const maxDiagnosisAlert = (
    <Alert leftIcon status="info">
      Maximum number of diagnoses attached. Remove diagnoses to add more.
    </Alert>
  )

  return (
    <div className={className} data-testid="diagnoses-section">
      <h3>Diagnoses</h3>
      {icd10Select()}
    </div>
  )
}
