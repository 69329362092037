import React from "react"
import DocumentDashboard from "components/DocumentDashboard/DocumentDashboard"
import LeftSidebar from "components/SupplierDashboard/LeftSidebar"

const SupplierDocumentDashboard = (props) => {
  return (
    <div className="position-relative">
      <LeftSidebar
        active={LeftSidebar.Options.Documents}
        counts={{
          orders: props.ordersCount,
          documents: props.counts.inboxTotal,
          authorizations: props.authorizationsCount,
        }}
        authorizationEnabled={props.carrierAuthorizationsEnabled}
      />
      <div className="row mt-n3">
        <div className="canopy-mbs-12x col-12">
          <h3>
            <span className="bold">Inbound Documents</span>
          </h3>
          <DocumentDashboard supplierId={props.supplierId} />
        </div>
      </div>
    </div>
  )
}

export default SupplierDocumentDashboard
