// @ts-strict-ignore
import React, { useState, useEffect } from "react"
import InternalNewOrderForm from "./components/InternalNewOrderForm"
import { makeApi } from "./api"
import {
  MasterPatient,
  CsrInboxDocument,
  ContextEmployer,
  EmployerType,
} from "sharedTypes"
import { employerTypeFromContext } from "utilities/url"
import { Supplier } from "applications/SupplierOrganizationSettings/components/SupplierSelector"
import { navigate, openNewTab } from "../../utilities/navigation"

type Props = {
  employer?: ContextEmployer
  masterPatient: MasterPatient
  patientSearch?: {
    firstName: string
    lastName: string
    dateOfBirth: string
    sex: string
  }
  document?: CsrInboxDocument
}

function NewOrderForm({
  employer,
  masterPatient,
  patientSearch,
  document,
}: Props) {
  const patientFromMasterPatient = masterPatient
    ? {
        firstName: masterPatient.firstName,
        lastName: masterPatient.lastName,
        dateOfBirth: masterPatient.dateOfBirth,
        sex: masterPatient.sex,
      }
    : null

  const [showModal, setShowModal] = useState(false)
  const [patient, setPatient] = useState(patientFromMasterPatient)
  const [supplierId, setSupplierId] = useState<string>(null)
  const [suppliers, setSuppliers] = useState<Supplier[]>([])
  const [api, setApi] = useState(
    makeApi(
      employerTypeFromContext(employer?.employerType),
      employer?.employerId
    )
  )

  useEffect(() => {
    const needsSupplier = () => {
      return (
        employer?.employerType === "supplier_organization" &&
        supplierId === null
      )
    }

    if (needsSupplier()) {
      const api = makeApi(
        employerTypeFromContext(employer.employerType),
        employer.employerId
      )

      api.fetchSuppliers().then((response) => {
        const responseSuppliers = response.data.result
        setSuppliers(responseSuppliers)
        if (responseSuppliers.length === 1) {
          setApi(makeApi("Supplier", responseSuppliers[0].id))
          setSupplierId(responseSuppliers[0].id)
        }
      })
    }
  }, [employer?.employerType, employer?.employerId, supplierId])

  const selectSupplier = (supplierId: string) => {
    setApi(makeApi("Supplier", supplierId))
    setSupplierId(supplierId)
  }

  const supplierEmployer = (): ContextEmployer => ({
    employerType: EmployerType.Supplier,
    employerId: supplierId,
    multiEmployersInOrganization: false,
    patientPortalEnabled: false,
  })

  const submit = (
    clinicalFacilityId,
    doctorId,
    selectedLegalEntityId,
    selectedExternalSystemBranchId,
    selectedSalesRepId,
    legalEntityAssignmentMethod
  ) => {
    const documentIds = document ? [document.documentId] : []
    return api
      .createDmeOrder(
        clinicalFacilityId,
        doctorId,
        masterPatient?.id,
        patient,
        documentIds,
        selectedLegalEntityId,
        selectedExternalSystemBranchId,
        selectedSalesRepId,
        legalEntityAssignmentMethod
      )
      .then((response) => {
        if (!!response.data.dmeOrderPath) {
          if (document) {
            openNewTab(response.data.dmeOrderPath)
            window.history.back()
          } else {
            navigate(response.data.dmeOrderPath)
          }
        }
      })
  }

  const openModal = () => setShowModal(true)
  const closeModal = () => {
    setPatient(patientFromMasterPatient)
    setSupplierId(null)
    setShowModal(false)
  }

  return (
    <InternalNewOrderForm
      employer={supplierId ? supplierEmployer() : employer}
      patient={patient}
      setPatient={setPatient}
      patientValidate={api.patientValidate}
      patientSearch={patientSearch}
      documentFacility={{
        id: document?.clinicalFacilityId,
        name: document?.clinicalFacilityName || "",
      }}
      submit={submit}
      openModal={openModal}
      closeModal={closeModal}
      showModal={showModal}
      newPatient={!masterPatient}
      supplierId={supplierId}
      suppliers={suppliers}
      setSupplierId={selectSupplier}
    />
  )
}

export default NewOrderForm
