import React, { useState } from "react"
import { FilePreviewer } from "../FilePreviewer"
import { get } from "services/api"
import Link from "components/Link"
import { handleError } from "utilities/error"
import { downloadALink } from "utilities/asyncDownload/downloadALink"

type FileDownloadData = { url: string; pageNumber?: number }
const fetchUrlForPage = async (
  pageNumber: number | null,
  orderId: string
): Promise<string> => {
  const url = `/web_api/documentation_orders/${orderId}/signed_pdf_url`
  return get(url).then((d: { data: { data: FileDownloadData[] } }) => {
    const pdfUrlData = d.data.data.find(
      (c) => c.url && c.pageNumber === pageNumber
    )
    if (!pdfUrlData) {
      throw Error("Failed to find the link for document")
    }
    return pdfUrlData.url
  })
}

const fetchUrlForFirstPageImage = (orderId: string) => {
  return fetchUrlForPage(1, orderId)
}
const downloadDocument = async (orderId: string) => {
  const nullForPageNumberWhichRepresentsTheDataForOriginalDocument = null
  return fetchUrlForPage(
    nullForPageNumberWhichRepresentsTheDataForOriginalDocument,
    orderId
  )
    .then((urlForDownload) =>
      downloadALink({ link: urlForDownload, filename: orderId + "signed.pdf" })
    )
    .catch((e) => handleError(e))
}
export const LinkWithPreview: React.FC<{
  orderId: string
  disabled?: boolean
}> = (props) => {
  const fetchFile = () => fetchUrlForFirstPageImage(props.orderId)
  const [disabled, setDisabled] = useState(props.disabled)
  if (props.disabled) return null
  const onClick = () => {
    if (disabled) return
    setDisabled(true)
    downloadDocument(props.orderId).then(() => setDisabled(false))
  }
  return (
    <>
      <Link
        aria-disabled={disabled}
        className={disabled ? "disabled link" : "link"}
        onClick={onClick}
      >
        Download
      </Link>
      {!props.disabled && <FilePreviewer fetchFileUrl={fetchFile} />}
    </>
  )
}
