// @ts-strict-ignore
import React from "react"
import Sidebar from "components/LeftSidebar"
import {
  carrierAuthorizationsDashboardUrl,
  inboundDocumentsDashboardUrl,
  ordersDashboardUrl,
  signatureRequestsDashboardUrl,
} from "./urls"
import { LocalShippingOutlined, Inbox } from "@material-ui/icons"
import DriveFileRenameOutlined from "./DriveFileRenameOutlinedIcon"
import Approval from "components/SupplierDashboard/ApprovalIcon"
import { fetchInitialLeftSidebarData } from "./api"
import withInitialData from "../withInitialData"

export enum Option {
  Orders = "Orders",
  SignatureRequests = "Signature Requests",
  InboundDocuments = "Inbound Documents",
  Authorizations = "Authorizations",
}

interface InitialData {
  signatureRequestDashboardEnabled: boolean
  carrierAuthorizationDashboardEnabled: boolean
  hasSuppliersWithUnworkedInboundDocuments: boolean
}
interface Props {
  active: Option
  initialData: InitialData
}

const LeftSidebar = ({ active, initialData }: Props) => (
  <Sidebar
    options={[
      {
        icon: LocalShippingOutlined,
        text: Option.Orders,
        href: ordersDashboardUrl(),
        active: active === Option.Orders,
        tooltip: "Orders Dashboard",
      },
      {
        icon: Inbox,
        text: Option.InboundDocuments,
        href: inboundDocumentsDashboardUrl(),
        active: active === Option.InboundDocuments,
        tooltip: "Inbound Documents Dashboard",
        badgeEnabled: initialData?.hasSuppliersWithUnworkedInboundDocuments,
      },
      initialData?.signatureRequestDashboardEnabled && {
        icon: DriveFileRenameOutlined,
        text: Option.SignatureRequests,
        href: signatureRequestsDashboardUrl(),
        active: active === Option.SignatureRequests,
        tooltip: "Signature Requests Dashboard",
      },
      initialData?.carrierAuthorizationDashboardEnabled && {
        icon: Approval,
        text: Option.Authorizations,
        href: carrierAuthorizationsDashboardUrl(),
        active: active === Option.Authorizations,
        tooltip: "Authorizations Dashboard",
      },
    ].filter(Boolean)}
    fixed
  />
)

LeftSidebar.Option = Option

const fetchInitialData = (): Promise<InitialData> => {
  return fetchInitialLeftSidebarData().then((response) => response)
}

export default withInitialData(
  fetchInitialData,
  (props: Omit<Props, "initialData">) => (
    <LeftSidebar {...props} initialData={null} />
  )
)(LeftSidebar)
