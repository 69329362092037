import React from "react"
import ParachuteMuiDataGridPro from "components/ParachuteMuiDataGrid/ParachuteMuiDataGrid"
import { GridColDef, GridRowData, GridRowParams } from "@mui/x-data-grid-pro"
import { UserConnection } from "graphql/__generated__/graphql"
import { useGraphqDataGrid } from "hooks/useGraphqlDataGrid/useGraphqlDataGrid"
import { isTest } from "utilities/environment"

interface Props {
  usersData: UserConnection
  loading: boolean
  paginate: any
  refetchData: any
  pageSize: number
  columnDefs: GridColDef[]
  getRowClassName: (params: GridRowParams, details?: any) => string
  className: string
}

export const UsersTable = ({
  usersData,
  loading,
  refetchData,
  paginate,
  pageSize,
  columnDefs,
  getRowClassName,
  className,
}: Props) => {
  const { nodes, pageInfo, totalCount } = usersData || {}
  const users: Readonly<GridRowData[]> = nodes ? (nodes as GridRowData[]) : []

  const { handlePagination, handleSorting, gridOptions } = useGraphqDataGrid(
    refetchData,
    paginate,
    pageInfo,
    pageSize
  )

  return (
    <ParachuteMuiDataGridPro
      columns={columnDefs}
      rows={users}
      loading={loading}
      getRowId={(row) => row.id}
      pagination={true}
      rowCount={totalCount}
      pageSize={pageSize}
      paginationMode="server"
      sortingMode="server"
      sortModel={gridOptions.sort}
      onSortModelChange={async (sortModel) => await handleSorting(sortModel)}
      autoHeight
      hideFooterSelectedRowCount={true}
      rowsPerPageOptions={[pageSize]}
      onPageChange={async (page) => await handlePagination(page)}
      disableVirtualization={isTest()}
      getRowClassName={getRowClassName}
      className={className}
    />
  )
}
