import React from "react"
import DateRangeFilterInput from "components/input/DateRangeFilter"

interface Props {
  initialDeliveryDateStart?: string
  initialDeliveryDateEnd?: string
  onChange: (values: {
    deliveryDateStartAt: string
    deliveryDateEndAt: string
  }) => void
}

const DateRangeFilter = ({
  initialDeliveryDateStart,
  initialDeliveryDateEnd,
  onChange,
}: Props) => (
  <DateRangeFilterInput
    name="deliveryDate"
    title="Requested Delivery Date"
    onChange={({ deliveryDateStartAt, deliveryDateEndAt }) => {
      onChange({ deliveryDateStartAt, deliveryDateEndAt })
    }}
    initialStartDate={initialDeliveryDateStart}
    initialEndDate={initialDeliveryDateEnd}
    enableReinitialize
    fullWidthPill
  />
)

export default DateRangeFilter
