import React, { createContext } from "react"

interface CsrInboxStateContextType {
  id: string
  supplier: { id: string }
  supplierOrganization: { id: string }
}

const defaultState: CsrInboxStateContextType = {
  id: "",
  supplier: { id: "" },
  supplierOrganization: { id: "" },
}
const CsrInboxStateContext = createContext<CsrInboxStateContextType>(
  defaultState
)

export const CsrInboxStateProvider: React.FC<CsrInboxStateContextType> = ({
  children,

  id,
  supplier,
  supplierOrganization,
}) => {
  const state = {
    id,
    supplier,
    supplierOrganization,
  }
  return (
    <CsrInboxStateContext.Provider value={state}>
      {children}
    </CsrInboxStateContext.Provider>
  )
}

export const useCsrInboxState = (): CsrInboxStateContextType => {
  const context = React.useContext(CsrInboxStateContext)
  return context
}

export default CsrInboxStateContext
