// @ts-strict-ignore
import React from "react"
import classNames from "classnames"
import { DmeOrder, Doctor } from "sharedTypes"
import { highlight } from "utilities/select"
import { pluralize } from "utilities/string"
import {
  canSign,
  cannotSignForMedicareOrders,
  medicareSigningAbilityUnknown,
} from "utilities/signature"
import Suggestion from "components/form/Select/Suggestion"

type Props = {
  doctor: Doctor
  dmeOrder: DmeOrder
  query?: string
  isHighlighted?: boolean
}

function getWarningText(doctor: Doctor, dmeOrder: DmeOrder): string {
  if (cannotSignForMedicareOrders(doctor, dmeOrder)) {
    return "Cannot sign for Medicare orders"
  }
  if (medicareSigningAbilityUnknown(doctor, dmeOrder)) {
    return "Medicare signing ability unknown"
  }
  return ""
}

function DoctorSuggestion({ doctor, dmeOrder, query, isHighlighted }: Props) {
  const warning = getWarningText(doctor, dmeOrder)
  const disabled = !canSign(doctor, dmeOrder)
  const address = [doctor.city, doctor.state].filter(Boolean).join(", ")
  return (
    <Suggestion
      key={doctor.doctorId}
      isHighlighted={isHighlighted}
      disabled={disabled}
    >
      {highlight(
        `${doctor.firstName} ${doctor.lastName} - ${doctor.npi}`,
        query
      )}
      {address && (
        <div
          className={classNames("float-right", {
            "color-dark-gray": !disabled,
            "color-mid-gray": disabled,
          })}
        >
          {address}
        </div>
      )}
      {doctor.clinicalOrganizations.length > 0 && (
        <>
          <br />
          <small>{doctor.clinicalOrganizations.join(", ")}</small>
        </>
      )}
      {!!doctor.signatureCount && !disabled && (
        <>
          <br />
          <small>
            {doctor.signatureCount} signed{" "}
            {pluralize("order", doctor.signatureCount)}
          </small>
        </>
      )}
      {warning && (
        <>
          <br />
          <small>{warning}</small>
        </>
      )}
    </Suggestion>
  )
}

export default DoctorSuggestion
