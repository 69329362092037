import React from "react"
import { Grid } from "@material-ui/core"
import { CanopyTextInputField } from "@parachutehealth/canopy-text-input-field"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { canopySpace16X } from "@parachutehealth/canopy-tokens-space"
import { Field, FormikErrors } from "formik"

export const FaxFieldRow = ({
  rowIndex,
  removeFaxField,
  removable,
  rowErrors,
  submitCount,
}: {
  rowIndex: number
  removeFaxField(): void
  removable: boolean
  rowErrors: FormikErrors<any>
  submitCount: number
}): JSX.Element => {
  return (
    <Grid
      container
      direction="row"
      spacing={1}
      alignItems="baseline"
      style={{ marginBottom: canopySpace16X }}
    >
      <Grid item>
        <Field
          as={CanopyTextInputField}
          description="Format: (###) ###-####"
          label="Fax number"
          name={`contacts.${rowIndex}.number`}
          feedbackMessage={
            submitCount > 0
              ? rowErrors[`contacts.${rowIndex}.number`]
              : undefined
          }
        />
      </Grid>
      <Grid item lg>
        <Field
          as={CanopyTextInputField}
          description="Ex: 'Primary' or 'Endocrinoligy Clinic'"
          label="Fax name"
          name={`contacts.${rowIndex}.name`}
        />
      </Grid>
      <Grid
        container
        item
        xs={2}
        style={{
          paddingBottom:
            submitCount > 0 && rowErrors[`contacts.${rowIndex}.number`]
              ? "29px"
              : "0",
          alignSelf: "flex-end",
        }}
      >
        {removable && (
          <CanopyButton
            iconStart="trash"
            variant="tertiary"
            accessibleText="remove additional fax field"
            title="remove additional fax"
            onClick={removeFaxField}
          />
        )}
      </Grid>
    </Grid>
  )
}
