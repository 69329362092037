const errorMessage = "can't be blank"

export const validateReferralForm = (values) => {
  const errors: {
    email?: string
    firstName?: string
    lastName?: string
    supplierId?: string
    branchId?: string
    salesRepId?: string
    supplierLegalEntityId?: string
  } = {}
  const requiredFields = [
    "email",
    "firstName",
    "lastName",
    "supplierId",
    "salesRepId",
    "supplierLegalEntityId",
  ]
  requiredFields.forEach((requiredField: string) => {
    if (!values[requiredField]) {
      errors[requiredField] = errorMessage
    }
  })

  return errors
}
