import React, { useContext } from "react"
import { capitalize } from "utilities/string"
import { format } from "utilities/date"
import Icon from "components/Icon"
import { fullName } from "utilities/person"
import { formatOrderType } from "utilities/orderType"
import { masterPatientUrl } from "../../urls"
import { DmeOrder, OrderType } from "sharedTypes"
import GlobalContext from "context/Global"
import * as styles from "./index.module.scss"

type Props = {
  disabled: boolean
  dmeOrder: DmeOrder
  editable: boolean
  onClick(): void
  supplierPatientIdVisible: boolean
}

function PatientLink({
  editable,
  dmeOrder,
  supplierPatientIdVisible,
  onClick,
  disabled,
}: Props): React.ReactElement {
  const { orderTypes, patient, patientSnapshot } = dmeOrder
  const { emrSession } = useContext(GlobalContext)
  const canEdit = editable && !emrSession
  const defaultSupplierSystemPatientId = dmeOrder.brightreeSupplier
    ? "Assign on Accept"
    : null
  const supplierSystemPatientId =
    patient.supplierSystemPatientId || defaultSupplierSystemPatientId

  const filteredOrderTypes = orderTypes
    .filter((t) => t !== OrderType.ManualDocumentationOrder)
    .map(formatOrderType)

  return (
    <div className={styles.patient} data-testid="patient">
      <div className="d-flex justify-content-space-between align-items-center">
        {disabled ? (
          <a className="link bold truncate disabled">{fullName(patient)}</a>
        ) : (
          <a
            target={emrSession ? "_self" : "_blank"}
            className="link bold truncate"
            href={masterPatientUrl(patient.masterPatientId)}
            rel="noreferrer"
          >
            {fullName(patient)}
          </a>
        )}
        {canEdit && (
          <a onClick={onClick} className="navbar-edit">
            <Icon type="pencil-alt" clickable />
          </a>
        )}
      </div>
      <div className={styles.patientInfoContainer}>
        <p className={styles.patientInfoItem}>
          <span className={styles.patientInfoLabel}>Sex: </span>
          {patient.sex === "unknown" ? "Unspecified" : capitalize(patient.sex)}
        </p>
        <p className={styles.patientInfoItem}>
          <span className={styles.patientInfoLabel}>DOB: </span>
          {`${format(patient.dateOfBirth)} (${patient.age}y)`}
        </p>
        {!!filteredOrderTypes.length && (
          <p className={styles.patientInfoItem}>
            <span className={styles.patientInfoLabel}>Order Types: </span>
            {filteredOrderTypes.join(", ")}
          </p>
        )}
        {patientSnapshot.mrn && (
          <>
            <p className={styles.patientInfoItem}>
              <span className={styles.patientInfoLabel}>MRN: </span>
              {`${patientSnapshot.mrn}`}
            </p>
          </>
        )}
        {dmeOrder.cachedEncounter?.visitNumber && (
          <>
            <p className={styles.patientInfoItem}>
              <span className={styles.patientInfoLabel}>Visit Number: </span>
              {`${dmeOrder.cachedEncounter.visitNumber}`}
            </p>
          </>
        )}
        {supplierPatientIdVisible && (
          <p className={styles.patientInfoItem}>
            <span className={styles.patientInfoLabel}>SSP ID: </span>
            {supplierSystemPatientId}
          </p>
        )}
      </div>
    </div>
  )
}

export default PatientLink
