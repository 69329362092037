import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core"
import classNames from "classnames"
import * as styles from "./index.module.scss"
import React from "react"
import {
  CatalogPackage,
  CatalogProductVariation,
  CatalogSeed,
} from "../../../../../../types/sharedTypes"
import NoRows from "../../../../../../components/DataGrid/NoRows"

type SeedDetailsTableProps = { seed: CatalogSeed }

const SeedDetailsTable: React.FC<SeedDetailsTableProps> = (
  props: SeedDetailsTableProps
): React.JSX.Element => {
  const { seed } = props

  type CatalogItem = CatalogPackage | CatalogProductVariation

  const createIdToItemMap = <T extends CatalogItem>(
    items: T[] | undefined
  ): { [id: number]: T | null } =>
    items?.reduce<{ [id: number]: T | null }>((map, item) => {
      map[item.id] = item.id === -1 ? null : item
      return map
    }, {}) || {}

  const packageIdToPackageMap: {
    [id: number]: CatalogPackage | null
  } = createIdToItemMap(seed.packages)
  const productVariationIdToProductVariationMap: {
    [id: number]: CatalogProductVariation | null
  } = createIdToItemMap(seed.productVariations)

  if (Object.keys(seed.packageIdsToProductVariationIds || {}).length === 0) {
    return (
      <div className={styles.noRows}>
        <NoRows
          message="There are currently no Packages or SKUs in this Seed."
          dataGrid={false}
        />
      </div>
    )
  } else {
    return (
      <TableContainer>
        <Table className={classNames(styles.table)}>
          <TableHead className={styles.tableHead}>
            <TableRow>
              <TableCell component="th" scope="col">
                Package
              </TableCell>
              <TableCell component="th" scope="col">
                Package ID
              </TableCell>
              <TableCell component="th" scope="col">
                SKU
              </TableCell>
              <TableCell component="th" scope="col">
                SKU ID
              </TableCell>
              <TableCell component="th" scope="col">
                HCPCS
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(seed.packageIdsToProductVariationIds || {}).map(
              ([packageId, productVariationIds]) => (
                <PackageRows
                  key={packageId}
                  packageData={packageIdToPackageMap[packageId]}
                  productVariationIds={productVariationIds}
                  productVariationData={productVariationIdToProductVariationMap}
                />
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }
}

type PackageRowsProps = {
  packageData: CatalogPackage | null
  productVariationIds: number[]
  productVariationData: { [id: number]: CatalogProductVariation | null }
}

const PackageRows: React.FC<PackageRowsProps> = ({
  packageData,
  productVariationIds,
  productVariationData,
}: PackageRowsProps) => {
  return (
    <>
      <TableRow>
        <TableCell scope="row" rowSpan={productVariationIds.length + 1}>
          <a href={packageData?.url}>{packageData?.name}</a>
        </TableCell>
        <TableCell rowSpan={productVariationIds.length + 1}>
          {packageData?.externalId}
        </TableCell>
        {productVariationIds.length === 0 && (
          <TableCell colSpan={2}>&nbsp;</TableCell>
        )}
      </TableRow>
      {productVariationIds.length > 0 &&
        productVariationIds.map((productVariationId) => (
          <TableRow key={productVariationId}>
            <TableCell scope="row">
              <a href={productVariationData?.[productVariationId]?.url}>
                {productVariationData?.[productVariationId]?.description}
              </a>
            </TableCell>
            <TableCell>
              {productVariationData?.[productVariationId]?.externalId}
            </TableCell>
            <TableCell>
              {productVariationData?.[productVariationId]?.hcpcs}
            </TableCell>
          </TableRow>
        ))}
    </>
  )
}

export default SeedDetailsTable
