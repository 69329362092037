import React, { useContext, useEffect } from "react"
import ContentArea from "../../../../components/ContentArea"
import { Form } from "../../../../../../components/form"
import { FormikProps } from "formik"
import { CanopyTextInputField } from "@parachutehealth/canopy-text-input-field"
import { stringifyErrors } from "../../../../utilities/form"
import { CanopyButton } from "@parachutehealth/canopy-button"
import * as styles from "./index.module.scss"
import {
  consignmentClosetDetailUrl,
  consignmentClosetsUrl,
} from "../../../../urls/consignmentClosets"
import {
  RouterChildContext,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom"
import FetchingCombobox from "../../../../components/FetchingCombobox"
import {
  duplicateConsignmentCloset,
  getConsignmentCloset,
  getFacilitiesForSupplier,
} from "../../../../api/consignmentClosets"
import {
  CatalogConsignmentCloset,
  NameAndId,
} from "../../../../types/sharedTypes"
import { isEmpty } from "lodash"
import { NoticeContext } from "../../../../contexts/NoticeContext"
import { isNullOrUndefined } from "../../../../../../utilities/isNullOrUndefined"
import { Backdrop, CircularProgress } from "@material-ui/core"
import classNames from "classnames"

const DuplicateConsignmentClosetForm: React.FC = (): React.JSX.Element => {
  const { consignmentClosetId } = useParams()
  const { showNotice } = useContext(NoticeContext)

  const initialValues: Record<string, any> = {
    name: "",
    clinicalFacilityId: "",
  }

  const [consignmentCloset, setConsignmentCloset] = React.useState<
    CatalogConsignmentCloset | undefined
  >()

  const history: RouterChildContext["router"]["history"] = useHistory()
  const location = useLocation()

  const [loading, setLoading] = React.useState<boolean>(
    isNullOrUndefined(consignmentCloset) &&
      !isNullOrUndefined(consignmentClosetId)
  )

  const loadConsignmentCloset = async (
    consignmentClosetId: string | number
  ) => {
    const consignmentCloset = await getConsignmentCloset(consignmentClosetId)
    setConsignmentCloset(consignmentCloset)
    setLoading(false)
  }

  useEffect(() => {
    if (consignmentClosetId) {
      void loadConsignmentCloset(consignmentClosetId)
    }
  }, [consignmentClosetId])

  const onCancel = (): void => {
    const queryParams = new URLSearchParams(location.search)
    const fromPage = queryParams.get("from")
    let url: string

    if (fromPage === "consignmentClosets" || !consignmentCloset) {
      url = consignmentClosetsUrl()
    } else {
      url = consignmentClosetDetailUrl(consignmentCloset.externalId)
    }
    history.push(url)
  }

  const handleSubmit = async (
    params: Record<string, any>,
    { setErrors }: any
  ) => {
    if (consignmentCloset && consignmentCloset.externalId) {
      await duplicateConsignmentCloset(consignmentCloset.externalId!, params)
        .then((response) => {
          if (response.consignmentCloset) {
            history.push(
              consignmentClosetDetailUrl(response.consignmentCloset.externalId)
            )
          } else {
            history.push(consignmentClosetsUrl())
          }

          showNotice(
            response.message || `New Closet has been duplicated`,
            "success"
          )
        })
        .catch((error) => {
          showNotice(
            error.response?.data?.message || "Something went wrong",
            "danger"
          )
          setErrors(error.response?.data?.errors)
        })
    } else {
      showNotice("Consignment closet data is missing", "danger")
    }
  }

  const fetchClinicalFacilities = async (
    supplierId: number,
    searchString: string
  ): Promise<NameAndId[]> => {
    if (isEmpty(searchString.trim())) return []

    return await getFacilitiesForSupplier(supplierId, {
      searchKeywords: searchString,
    })
  }

  return loading ? (
    <Backdrop style={{ color: "#fff", zIndex: 1_000 }} open={loading}>
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : (
    <>
      <h2 className="canopy-typography-heading-xlarge">
        Duplicate Consignment Closet
      </h2>
      <body>
        Carry over Supplier Package and Package Configurations from the
        duplicated Closet
      </body>
      <ContentArea
        variant="medium"
        className={classNames(styles.duplicationInfo)}
      >
        <div>
          <h5 className={classNames(styles.subheading)}>Source facility</h5>
          <span className="canopy-typography-body-medium">
            {consignmentCloset?.clinicalFacilityName}
          </span>
        </div>
        <div>
          <h5 className={classNames(styles.subheading)}>Closet to duplicate</h5>
          <span className="canopy-typography-body-medium">
            {consignmentCloset?.name}
          </span>
        </div>
      </ContentArea>
      <ContentArea variant="medium">
        <Form
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          {(formik: FormikProps<any>) => {
            return (
              <>
                <CanopyTextInputField
                  className="canopy-mb-8x"
                  label="New Closet Supplier"
                  value={consignmentCloset?.supplierName}
                  required
                  disabled
                  size="small"
                  id="supplierId"
                />

                <FetchingCombobox<NameAndId>
                  className="canopy-mb-8x"
                  label="New Closet Facility"
                  required
                  size="small"
                  placeholder="Select a facility"
                  id="clinicalFacilityId"
                  onSelection={(newValue: NameAndId[]) =>
                    formik.setFieldValue(
                      "clinicalFacilityId",
                      newValue.at(0)?.id
                    )
                  }
                  fetchItems={(search: string) => {
                    {
                      if (consignmentCloset && consignmentCloset.supplierId) {
                        return fetchClinicalFacilities(
                          consignmentCloset.supplierId,
                          search
                        )
                      }
                      formik.setFieldError(
                        "clinicalFacilityId",
                        "Supplier is undefined"
                      )
                      return Promise.resolve([]) // Return an empty array if supplierId is undefined
                    }
                  }}
                  getOptionLabel={(item: NameAndId) => item.name}
                  // rails sends back the error under "clinical_facility" and not "clinical_facility_id"
                  feedbackMessage={stringifyErrors(
                    formik.errors,
                    "clinicalFacility"
                  )}
                />

                <CanopyTextInputField
                  className="canopy-mb-8x"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="name"
                  size="small"
                  label="New Closet name"
                  value={formik.values["name"]}
                  required
                  feedbackMessage={stringifyErrors(formik.errors, "name")}
                />

                <CanopyButton
                  size="small"
                  variant="primary"
                  type="submit"
                  loading={formik.isSubmitting}
                >
                  Duplicate
                </CanopyButton>
                <CanopyButton
                  className="canopy-mis-4x"
                  size="small"
                  variant="tertiary"
                  disabled={formik.isSubmitting}
                  onClick={onCancel}
                >
                  Cancel
                </CanopyButton>
              </>
            )
          }}
        </Form>
      </ContentArea>
    </>
  )
}

export default DuplicateConsignmentClosetForm
