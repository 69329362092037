import { KeyboardEvent } from "react"
import { Keycode } from "enums/keycodes"

export const isEnter = (event: KeyboardEvent): boolean => event.key === "Enter"

export const isTab = (event: KeyboardEvent): boolean => event.key === "Tab"

export const isTabOrEnter = (event: KeyboardEvent): boolean =>
  isEnter(event) || isTab(event)

export const isCtrlEnter = (event: KeyboardEvent): boolean =>
  event.ctrlKey === true && isEnter(event)

export const getKeyCode = (event) => event.which || event.keyCode || 0

export const blurOnEnter = (event) => {
  if (getKeyCode(event) === Keycode.Enter) {
    event.target.blur()
  }
}
