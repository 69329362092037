import React from "react"
import Suggestion from ".."

interface Props {
  minlength: number
}

const TypeMoreCharactersSuggestion = ({ minlength }: Props) => {
  return (
    <Suggestion
      small
      unselectable
    >{`Type ${minlength} or more characters to search`}</Suggestion>
  )
}

export default TypeMoreCharactersSuggestion
