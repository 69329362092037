import React from "react"
import { CanopyButton } from "@parachutehealth/canopy-button"
import Confirm from "components/Confirm"
import { useCsrInboxState } from "applications/SupplierOrganizationInboxDocument/csrInboxStateContext"
import { discardDocument } from "./discardDocument"
import { navigate, reload } from "utilities/navigation"

import * as styles from "./index.module.scss"

interface Props {
  dashboardUrl: string
  documentTitle: string | undefined
}

const DiscardModule = ({ documentTitle, dashboardUrl }) => {
  const csrInboxState = useCsrInboxState()
  const documentReference = documentTitle ? documentTitle : "this document"

  const handleDiscardClick = async () => {
    discardDocument({
      supplierOrganizationId: csrInboxState.supplierOrganization.id,
      csrInboxStateId: csrInboxState.id,
    })
      .then(() => {
        navigate(dashboardUrl)
      })
      .catch(() => {
        reload()
      })
  }

  return (
    <Confirm
      title="Discard document(s)?"
      description={
        <>
          Are you sure you want to discard <b>{documentReference}</b>? You can
          recover it later by reopening the document and clicking 'Restore.'
        </>
      }
      confirmText="Discard"
      cancelText="Cancel"
      render={(confirm) => (
        <CanopyButton
          iconStart="trash"
          size="small"
          variant="tertiary"
          onClick={confirm(handleDiscardClick)}
        >
          Discard
        </CanopyButton>
      )}
    />
  )
}

export const SupplierIntakeNavbar = ({
  dashboardUrl,
  documentTitle,
}: Props) => {
  return (
    <nav className={styles.supplierIntakeNavbar}>
      <CanopyButton
        as="a"
        href={dashboardUrl}
        iconStart="arrow-left"
        size="small"
        variant="tertiary"
      >
        Return to dashboard
      </CanopyButton>
      <DiscardModule
        documentTitle={documentTitle}
        dashboardUrl={dashboardUrl}
      />
    </nav>
  )
}
