// @ts-strict-ignore
import React from "react"
import {
  DmeOrder,
  Document,
  Employer,
  EmployerType,
  RxDetailsOutput,
  DmeOrderWarning,
  LineItemGroup,
  OfferingType,
  OrderStatus,
  QuestionnaireResponse,
} from "sharedTypes"
import { toList } from "utilities/array"
import { fullName } from "utilities/person"
import ReviewCard from "./ReviewCard/ReviewCard"
import CartReviewCard from "./ReviewCard/variants/CartReviewCard/CartReviewCard"
import DocumentationRequirementsReviewCard from "./ReviewCard/variants/DocumentationRequirementsReviewCard/DocumentationRequirementsReviewCard"
import SignatureReviewCard from "./ReviewCard/variants/SignatureReviewCard/SignatureReviewCard"
import MedicalNecessityReviewCard from "./ReviewCard/variants/MedicalNecessityReviewCard/MedicalNecessityReviewCard"
import PatientSectionContent from "./SectionContent/PatientSectionContent"
import PaymentSectionContent from "./SectionContent/PaymentSectionContent"
import DeliverySectionContent from "./SectionContent/DeliverySectionContent"
import DiagnosesSectionContent from "./SectionContent/DiagnosesSectionContent"
import DocumentDownloads from "./DocumentDownloads"
import ContactsSectionContent from "./SectionContent/ContactsSectionContent"
import { getDeliveryStatusSeverity } from "utilities/deliveryStatus"
import { packagesForFulfillment } from "../utilities"
import startCase from "lodash/startCase"
import { formatPhone } from "utilities/phone"
import DeliveryStatus, { DEFAULT_STATUS } from "components/DeliveryStatus"
import {
  PAYMENT_STEP,
  SIGNATURE_STEP,
} from "applications/Workflow/utilities/steps"
import { FormData } from "applications/FulfillmentEditor/components/FulfillmentForm"
import { warningSeverity } from "../utilities/validation"
import { Permissions, SignatureRequest } from "../sharedTypes"
import {
  getSignatureStatusSeverity,
  showSignatureStatus,
} from "utilities/signatureStatus"
import SignatureStatus from "components/SignatureStatus"
import { addressesByType } from "utilities/address"
import DeliveryReceiptLinks from "./DeliveryReceiptLinks"
import cx from "classnames"
import { useFeatureFlags } from "../../../../../components/FeatureFlagContext"

type Props = {
  currentEmployer: Employer
  deliveryTicketSignedAt: string
  dmeOrder: DmeOrder
  downloadableDocuments: Document[]
  lineItemGroups: LineItemGroup[]
  rxDetailsOutputs: RxDetailsOutput[]
  permissions: Permissions
  requiresTwoStepDownload: boolean
  sendSignatureRequest(params: {}): Promise<void>
  signatureRequests: SignatureRequest[]
  signProofOfDelivery(): void
  warnings: DmeOrderWarning[]
  updateFulfillment(id: string, values: FormData): Promise<void>
  updateClinicalFacility: () => void
  questionnaireResponses: QuestionnaireResponse[]
  isOptumFlow: boolean
}

const ReviewCards = (props: Props) => {
  const {
    currentEmployer,
    deliveryTicketSignedAt,
    dmeOrder,
    downloadableDocuments,
    lineItemGroups,
    rxDetailsOutputs,
    permissions,
    requiresTwoStepDownload,
    sendSignatureRequest,
    signatureRequests,
    signProofOfDelivery,
    warnings,
    updateFulfillment,
    updateClinicalFacility,
    questionnaireResponses,
    isOptumFlow,
  } = props

  const { isFeatureEnabled } = useFeatureFlags()
  const demoClinicalGuidelines =
    isFeatureEnabled("demoClinicalGuidelines") && dmeOrder.optum

  const expandSections = currentEmployer.employerType === EmployerType.Supplier
  const equipmentFulfillments = dmeOrder.fulfillments.filter(
    (fulfillment) => fulfillment.offeringType === OfferingType.Equipment
  )

  const fulfillmentSummary = (fulfillment) => {
    const deliveryAddress = dmeOrder.addresses.find(
      (address) => address.id === fulfillment.deliveryAddressId
    )
    return `${deliveryAddress.line1}: ${packagesForFulfillment(
      dmeOrder,
      fulfillment
    )}`
  }

  const signatureCardStatus = () => {
    if (
      showSignatureStatus(dmeOrder.orderStatus, currentEmployer.employerType)
    ) {
      return getSignatureStatusSeverity(dmeOrder.signatureStatus)
    }
    return warningSeverity(SIGNATURE_STEP, warnings)
  }

  const addressTypeToPhone = {
    facility: "facility",
    billing: "patient",
  }

  return (
    <div className={cx("wide-fixed-row", "canopy-pbe-8x")}>
      <ReviewCard
        expanded={expandSections}
        title="Patient"
        summary={fullName(dmeOrder.patient)}
      >
        <PatientSectionContent
          dmeOrder={dmeOrder}
          currentEmployer={currentEmployer}
          allowUpdateClinicalFacility={permissions.updateClinicalFacility}
          updateClinicalFacility={updateClinicalFacility}
        />
      </ReviewCard>
      <ReviewCard
        status={warningSeverity(PAYMENT_STEP, warnings)}
        title="Payment"
        summary={toList(
          dmeOrder.insurancePolicies.map(
            (p) =>
              `${p.carrierName ? p.carrierName : "Missing Carrier"} - ${
                p.memberNumber
              }`
          ),
          3
        )}
        expanded={expandSections}
      >
        <PaymentSectionContent dmeOrder={dmeOrder} />
      </ReviewCard>
      <CartReviewCard
        dmeOrder={dmeOrder}
        expanded
        currentEmployer={currentEmployer}
        lineItemGroups={lineItemGroups}
        rxDetailsOutputs={rxDetailsOutputs}
        warnings={warnings}
        canUpdateCustomAttributeConfiguration={
          permissions.updateCustomAttributeConfiguration
        }
        canUpdateSupplierLegalEntity={permissions.updateSupplierLegalEntity}
      />
      {equipmentFulfillments.map((fulfillment, index) => {
        return (
          <ReviewCard
            key={fulfillment.id}
            title={`Delivery #${index + 1}`}
            status={getDeliveryStatusSeverity(fulfillment.deliveryStatus)}
            expanded={expandSections}
            summary={fulfillmentSummary(fulfillment)}
            badge={() => {
              const defaultStatus =
                dmeOrder.orderStatus === OrderStatus.Accepted
                  ? DEFAULT_STATUS
                  : null
              return (
                <DeliveryStatus
                  status={fulfillment.deliveryStatus || defaultStatus}
                  className="canopy-mie-4x"
                />
              )
            }}
          >
            <DeliverySectionContent
              dmeOrder={dmeOrder}
              fulfillment={fulfillment}
              updateFulfillment={updateFulfillment}
              currentEmployer={currentEmployer}
            />
          </ReviewCard>
        )
      })}
      <ReviewCard
        title="Contacts"
        summary={toList(
          dmeOrder.addresses
            .filter((a) => !!a.phoneNumber && addressTypeToPhone[a.addressType])
            .sort(addressesByType)
            .map(
              (a) =>
                `${startCase(addressTypeToPhone[a.addressType])}: ${formatPhone(
                  a.phoneNumber
                )}`
            ),
          3
        )}
        expanded={expandSections}
      >
        <ContactsSectionContent dmeOrder={dmeOrder} />
      </ReviewCard>
      <ReviewCard
        status={warningSeverity("diagnoses", warnings)}
        title="Diagnoses"
        summary={toList(
          dmeOrder.icd10Codes.map((c) => c.codeLabel),
          3
        )}
        expanded={expandSections}
      >
        <DiagnosesSectionContent dmeOrder={dmeOrder} />
      </ReviewCard>
      {isOptumFlow || demoClinicalGuidelines ? (
        <MedicalNecessityReviewCard
          currentEmployer={currentEmployer}
          dmeOrder={dmeOrder}
          expanded={expandSections}
          warnings={warnings}
          questionnaireResponses={questionnaireResponses}
        />
      ) : (
        <DocumentationRequirementsReviewCard
          currentEmployer={currentEmployer}
          dmeOrder={dmeOrder}
          expanded={expandSections}
          warnings={warnings}
        />
      )}
      <SignatureReviewCard
        status={signatureCardStatus()}
        expanded={expandSections}
        badge={() => (
          <SignatureStatus
            signatureStatus={dmeOrder.signatureStatus}
            orderStatus={dmeOrder.orderStatus}
            employerType={currentEmployer.employerType}
            className="canopy-mie-4x"
          />
        )}
        dmeOrder={dmeOrder}
        permissions={permissions}
        sendSignatureRequest={sendSignatureRequest}
        signatureRequests={signatureRequests}
      />
      <ReviewCard title="More Actions" dropdown={false}>
        <DocumentDownloads
          dmeOrder={dmeOrder}
          downloadableDocuments={downloadableDocuments}
          requiresTwoStepDownload={requiresTwoStepDownload}
        />
        {permissions.signDeliveryTicket && (
          <DeliveryReceiptLinks
            signProofOfDelivery={signProofOfDelivery}
            deliveryTicketSignedAt={deliveryTicketSignedAt}
          />
        )}
      </ReviewCard>
    </div>
  )
}

ReviewCards.defaultProps = {
  warnings: [],
}

export default ReviewCards
