// @team @catalog-crew
import { patch, post, put } from "../../../services/api"
import { CatalogCustomAttributeOption } from "../types/sharedTypes"
import { ApiResponse } from "./utilities"

export const rearrangeAttributeOptions = async (
  attributeId: string,
  ids: string[]
): Promise<boolean> => {
  return await put(
    `/cms/catalog/attributes/${attributeId}/custom_attribute_options/rearrange`,
    { ids: ids }
  )
    .then((_response) => true)
    .catch((_error) => false)
}

export const archiveAttributeOption = async (
  attributeId: string,
  attributeOptionId: string
): Promise<boolean> => {
  return await put(
    `/cms/catalog/attributes/${attributeId}/custom_attribute_options/${attributeOptionId}/archive.json`
  )
    .then((_response) => true)
    .catch((_error) => false)
}

export const updateAttributeOption = async (
  attributeId: string,
  attributeOptionId: string,
  params: Record<string, any>
): Promise<
  ApiResponse<{ customAttributeOption: CatalogCustomAttributeOption }>
> => {
  return await patch(
    `/cms/catalog/attributes/${attributeId}/custom_attribute_options/${attributeOptionId}.json`,
    { catalog_custom_attribute_option: params }
  )
    .then((response) => response.data)
    .catch((error) => error.response.data)
}

export const createAttributeOption = async (
  attributeId: string,
  params: Record<string, any>
): Promise<
  ApiResponse<{ customAttributeOption: CatalogCustomAttributeOption }>
> => {
  return await post(
    `/cms/catalog/attributes/${attributeId}/custom_attribute_options.json`,
    { catalog_custom_attribute_option: params }
  )
    .then((response) => response.data)
    .catch((error) => error.response.data)
}
