import React, { ReactNode, useContext } from "react"
import { SidebarLink } from "sharedTypes"
import WorkflowPageContext from "context/WorkflowPage"
import { SidebarTab } from "../../applications/Workflow/containers/Navigation/sharedTypes"
import * as tokens from "@parachutehealth/canopy-tokens-color"

type Props = {
  links: SidebarLink[]
  portalMessagingEnabled: boolean
}

const linkColor = tokens.canopyColorInteractiveLinkDefault
const linkStyle = {
  color: linkColor,
  textDecoration: "underline",
}

const SidebarLinks: React.FC<Props> = ({ links, portalMessagingEnabled }) => {
  const { setSidebarTab } = useContext(WorkflowPageContext)

  const navigateToTab = (tab: SidebarTab) => {
    if (tab === SidebarTab.PatientMessaging && !portalMessagingEnabled) {
      return
    }
    setSidebarTab(tab)
  }

  const renderLink = (link: SidebarLink): ReactNode => {
    return (
      <div
        key={`${link.tab}-${link.text}`}
        role="button"
        className="d-inline-block"
        style={linkStyle}
        onClick={() => navigateToTab(link.tab)}
      >
        {link.text}
      </div>
    )
  }

  return <div>{links.map(renderLink)}</div>
}

export default SidebarLinks
