import React from "react"
import classNames from "classnames"
import * as styles from "./index.module.scss"
import { Drawer } from "@material-ui/core"
import { CanopyIcon } from "@parachutehealth/canopy-icon"

export type CmsDrawerProps = {
  open?: boolean
  title: React.ReactNode
  onClose: () => void
  children: React.ReactNode
  anchor?: "left" | "top" | "right" | "bottom"
}

/**
 * This component is an extremely thin wrapper around MUI's Drawer component, but with various styles
 * centralized so they aren't copied and pasted for every invocation.
 */
const CmsDrawer: React.FC<CmsDrawerProps> = ({
  children,
  title,
  onClose,
  open = false,
  anchor = "right",
}: CmsDrawerProps): React.JSX.Element => {
  return (
    <Drawer
      role="dialog"
      aria-labelledby="cms-drawer-header"
      variant="persistent"
      open={open}
      anchor={anchor}
      className={classNames(styles.cmsDrawer)}
      transitionDuration={500}
    >
      <div className={styles.headerContainer}>
        <h3 id="cms-drawer-header">{title}</h3>
        <button
          aria-label="Close"
          className="close"
          type="button"
          onClick={onClose}
        >
          <CanopyIcon
            name="xmark"
            size="medium"
            fill="canopyColorPrimitivesGray27"
          ></CanopyIcon>
        </button>
      </div>

      <div className="canopy-p-12x">{children}</div>
    </Drawer>
  )
}

export default CmsDrawer
