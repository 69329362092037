import React from "react"
import {
  patientPortalMessageTemplateUrl,
  patientPortalMessageTemplatesUrl,
} from "../urls"
import { styleTemplateVariables } from "../helpers"

interface MessageTemplate {
  id: string
  name: string
  template: string
}

interface PatientPortalMessageTemplatesProps {
  supplierName: string
  messageTemplates: MessageTemplate[]
}

const PatientPortalMessageTemplates = ({
  messageTemplates,
  supplierName,
}: PatientPortalMessageTemplatesProps) => {
  return (
    <div>
      <div className="d-flex">
        <div>
          <h1>Patient Message Templates ({supplierName})</h1>
          <p>
            {supplierName} users can select a template from within the Patient
            Messaging pane. Users can view the templated message prior to
            sending and edit as needed.
          </p>
        </div>
        <a
          href={`${patientPortalMessageTemplatesUrl()}/new`}
          className="canopy-ml-auto btn btn-primary"
        >
          + Add Template
        </a>
      </div>

      <table style={{ tableLayout: "fixed" }} className="table table-hover">
        <thead>
          <tr>
            <th style={{ width: "20%" }}>Name</th>
            <th style={{ width: "68%" }}>Message</th>
            <th style={{ width: "12%" }}></th>
          </tr>
        </thead>
        <tbody>
          {messageTemplates.map((messageTemplate) => (
            <tr key={messageTemplate.id}>
              <td>{messageTemplate.name}</td>
              <td className="text-truncate">
                {styleTemplateVariables(messageTemplate.template)}
              </td>
              <td>
                <a
                  href={`${patientPortalMessageTemplateUrl(
                    messageTemplate.id
                  )}/edit`}
                  className="link"
                >
                  Edit
                </a>
                {" | "}
                <a
                  href={patientPortalMessageTemplateUrl(messageTemplate.id)}
                  data-method="delete"
                  className="link"
                >
                  Delete
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default PatientPortalMessageTemplates
