import { post, put } from "services/api"
import { makeUrlProvider } from "./urls"
import { EmployerType } from "utilities/url"

export const makeApi = (employerType: EmployerType, employerId: string) => {
  const urls = makeUrlProvider(employerType, employerId)
  return {
    createDmeOrder: (
      clinicalFacilityId,
      masterPatientId,
      patientId = null,
      cachedEncounterId = null
    ) => {
      return post(urls.createDmeOrderUrl, {
        dmeOrder: {
          clinicalFacilityId,
          masterPatientId,
          patientId,
          cachedEncounterId,
        },
      })
    },
    reorderDmeOrder: (dmeOrderId) => {
      return post(urls.reorderDmeOrderUrl(dmeOrderId), {})
    },
    updateHospiceDates: (
      id,
      hospiceEpisodeAdmitDate,
      hospiceEpisodeDischargeDate
    ) => {
      return put(urls.updateHospiceUrl(id), {
        patient: { hospiceEpisodeAdmitDate, hospiceEpisodeDischargeDate },
        ...{ id },
      })
        .then(({ data }) => data)
        .catch(({ response }) => Promise.reject(response.data))
    },
    sendEmrResetPasswordEmail: () => {
      return post(urls.sendEmrResetPasswordEmailUrl)
        .then(({ data }) => data)
        .catch(({ response }) => Promise.reject(response.data))
    },
  }
}
