import React, { useContext } from "react"
import { DmeOrder, Document } from "sharedTypes"
import DownloadLink from "components/DownloadLink"
import Alert from "components/Alert"
import WorkflowPageContext from "context/WorkflowPage"
import { SidebarTab } from "applications/Workflow/containers/Navigation/sharedTypes"

type Props = {
  dmeOrder: DmeOrder
  downloadableDocuments: Document[]
  requiresTwoStepDownload: boolean
}

const DocumentDownloads = ({
  dmeOrder,
  downloadableDocuments,
  requiresTwoStepDownload,
}: Props) => {
  const workflowPageContext = useContext(WorkflowPageContext)
  const orderId = dmeOrder.id
  if (downloadableDocuments.length === 0) {
    return (
      <div className="alert alert-warning alert-rounded alert-borderless">
        There are no documents available to download at this time.
      </div>
    )
  }
  const openDocumentsTab = () =>
    workflowPageContext.setSidebarTab(SidebarTab.Documents)
  return (
    <>
      <ul className="list-unstyled canopy-mbe-0">
        <Alert leftIcon status="info" onClick={openDocumentsTab}>
          <strong>There's a new way to attach documents.</strong> Upload or fax
          documents for this order from the documents panel.{" "}
          <a>Open Documents Panel</a>
        </Alert>
        {downloadableDocuments.map(
          ({ key, title, dmeOrderDocumentationRequirementId }) => (
            <li key={key}>
              <DownloadLink
                orderId={orderId}
                dmeOrderDocumentationRequirementId={
                  dmeOrderDocumentationRequirementId
                }
                documentKey={key}
                label={title}
                className="link"
                requiresTwoStepDownload={requiresTwoStepDownload}
              />
            </li>
          )
        )}
      </ul>
    </>
  )
}

export default DocumentDownloads
