// @team @catalog-crew
import React, { createContext, useState } from "react"
import { Notice, NoticeProps } from "../components/Notice"

type NoticeContext = {
  showNotice: (
    message: NoticeProps["children"],
    type: NoticeProps["type"],
    link?: NoticeProps["link"]
  ) => void
  clearNotice: () => void
}

export const NoticeContext = createContext<NoticeContext>({
  showNotice: () => {},
  clearNotice: () => {},
})

export type NoticeState = {
  open: boolean
  message: NoticeProps["children"]
  type: NoticeProps["type"]
  link?: NoticeProps["link"]
}

export const NoticeProvider = ({ children }) => {
  const [noticeState, setNoticeState] = useState<NoticeState>({
    open: false,
    message: "",
    type: "success",
  })

  const showNotice = (
    message: NoticeProps["children"],
    type: NoticeProps["type"],
    link?: NoticeProps["link"]
  ) => {
    setNoticeState({ open: true, message, type, link })
  }

  const clearNotice = () => {
    setNoticeState({
      ...noticeState,
      open: false,
      message: "",
      link: undefined,
    })
  }

  return (
    <NoticeContext.Provider value={{ showNotice, clearNotice }}>
      {noticeState.open && (
        <Notice
          onClose={() => setNoticeState({ ...noticeState, open: false })}
          type={noticeState.type}
          link={noticeState.link}
        >
          {noticeState.message}
        </Notice>
      )}
      {children}
    </NoticeContext.Provider>
  )
}

const wrap = (Component) => (props) => (
  <NoticeProvider>
    <Component {...props} />
  </NoticeProvider>
)

export const withNoticeProvider = (Component) => wrap(Component)
