import React, { ComponentType } from "react"
import EventComponent from "components/Event"
import { Event, EventAction, Notice, Nullable } from "sharedTypes"
import Timeline from "components/Timeline"
import { CSSTransition, TransitionGroup } from "react-transition-group"

type BaseEventProps = {
  activeUser?: boolean
  currentUserId?: string
  event: Event
  notice?: Nullable<Notice>
}

type Props = {
  clinicalFacility: { id: string; name: string }
  events: Event[]
  activeUsers: string[]
  portalMessagingEnabled?: boolean
  CustomEventComponent?: ComponentType<BaseEventProps>
}

const Events: React.FC<Props> = ({
  clinicalFacility,
  events,
  activeUsers,
  portalMessagingEnabled,
  CustomEventComponent,
}) => {
  const renderEvent = (event: Event) => {
    const isHiddenComment =
      event.action === EventAction.InternalComment ||
      event.action === EventAction.SupplierComment ||
      event.action === EventAction.PatientMessage

    const baseProps: BaseEventProps = {
      activeUser: activeUsers.includes(event.userId),
      currentUserId: window.parachute?.currentUserId,
      event: event,
      notice: getNotice(event, isHiddenComment),
    }

    return (
      <Timeline
        key={event.eventTimestamp}
        event={event}
        internal={isHiddenComment}
      >
        {CustomEventComponent ? (
          <CustomEventComponent {...baseProps} />
        ) : (
          <EventComponent
            {...baseProps}
            portalMessagingEnabled={portalMessagingEnabled}
          />
        )}
      </Timeline>
    )
  }

  const getNotice = (
    event: Event,
    isHiddenComment: boolean
  ): Nullable<Notice> => {
    if (!isHiddenComment) return null
    return {
      content: `Not visible to ${clinicalFacility.name}`,
      type:
        event.action === EventAction.SupplierComment ? "SUPPLIER" : "INTERNAL",
    }
  }

  return (
    <TransitionGroup className="timeline-event-list">
      {events.map((event) => (
        <CSSTransition
          key={event.eventTimestamp}
          timeout={{ enter: 300, exit: 250 }}
          mountOnEnter
          unmountOnExit
          classNames="timeline-event"
        >
          {renderEvent(event)}
        </CSSTransition>
      ))}
    </TransitionGroup>
  )
}

export default Events
