import React, { useState } from "react"
import Modal from "components/Modal"
import { Form, TextField } from "components/form"
import Overlay from "components/Overlay"

type Props = {
  updateSupplierFulfillment(supplierSystemId): Promise<void>
  close(): void
  supplierSystemId?: string
}

const OrderLinkageModal = ({
  close,
  supplierSystemId,
  updateSupplierFulfillment,
}: Props) => {
  const [showOverlay, setShowOverlay] = useState(false)
  const onSubmit = (values) => {
    setShowOverlay(true)
    return updateSupplierFulfillment(values.supplierSystemId)
      .then(() => close())
      .catch(() => setShowOverlay(false))
  }
  return (
    <Modal show title="BT Sales ID" closeable cancel={close} size="md">
      <Modal.Body>
        <Overlay
          active={showOverlay}
          showSpinner
          position="top"
          className="overlay-flex-height"
        >
          <Form onSubmit={onSubmit} initialValues={{ supplierSystemId }}>
            <TextField name="supplierSystemId" />
            <div className="row">
              <div className="col-12">
                <button className="btn btn-brand float-right" type="submit">
                  Save
                </button>
              </div>
            </div>
          </Form>
        </Overlay>
      </Modal.Body>
    </Modal>
  )
}

export default OrderLinkageModal
