import React from "react"
import { MentionsInput, Mention } from "react-mentions"
import * as styles from "../index.module.scss"
import { canopyColorPrimitivesPurple94 } from "@parachutehealth/canopy-tokens-color"

const TemplateFormField = ({ form, field, values, label }) => {
  const variables = ["Patient First Name", "Patient Full Name", "Supplier Name"]
  const inputRef = React.useRef<HTMLInputElement>(null)

  return (
    <div className="form-group">
      <label htmlFor="template">{label}</label>
      <div className="canopy-mbe-4x">
        Add Customization
        {variables.map((v) => (
          <button
            key={v}
            type="button"
            className={styles.addVariableButton}
            onClick={() => {
              const cursorPosition =
                inputRef.current!.selectionStart || values["template"].length
              const start = values["template"].slice(0, cursorPosition)
              const end = values["template"].slice(cursorPosition)
              form.setFieldValue("template", start + `{${v}}` + end)

              inputRef.current!.focus()
              setImmediate(() => {
                inputRef.current!.setSelectionRange(
                  cursorPosition + v.length + 2,
                  cursorPosition + v.length + 2
                )
              })
            }}
          >
            {v}
          </button>
        ))}
      </div>
      <MentionsInput
        value={field.value}
        onChange={(_event, newValue, _newPlainTextValue, _mentions) => {
          form.setFieldValue(field.name, newValue)
        }}
        className="text-editor form-control"
        inputRef={inputRef}
      >
        <Mention
          markup="{__id__}"
          trigger=""
          data={[]}
          appendSpaceOnAdd
          style={{
            backgroundColor: canopyColorPrimitivesPurple94,
            marginLeft: "2px",
          }}
          displayTransform={(_id, display) => ` ${display} `}
        />
      </MentionsInput>
    </div>
  )
}

export default TemplateFormField
