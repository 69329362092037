import React from "react"
import Attestations from "../Attestations"
import SelfSignAttestation from "components/SelfSignAttestation"
import SignOnScreenAttestation from "../SignOnScreenAttestation"
import {
  DmeOrder,
  DmeOrderWarning,
  Employer,
  EmployerType,
  SignatureType,
} from "sharedTypes"

export type Props = {
  warnings: DmeOrderWarning[]
  dmeOrder: DmeOrder
  selfSign: boolean
  currentEmployer: Employer
  showSubmit: boolean
  extraAttestationRequested: boolean
  isExtraAttestationChecked: boolean
  setExtraAttestationChecked(boolean): void
}

const AttestationsSection = (props: Props) => {
  const {
    warnings,
    dmeOrder,
    selfSign,
    currentEmployer,
    showSubmit,
    extraAttestationRequested,
    isExtraAttestationChecked,
    setExtraAttestationChecked,
  } = props
  const isFacility =
    currentEmployer.employerType === EmployerType.ClinicalFacility
  const showSelfSign = selfSign && showSubmit
  const signOnScreen =
    dmeOrder.signatureType === SignatureType.OnScreen && isFacility
  const showOnScreenAttestation = showSubmit && signOnScreen && !showSelfSign

  return (
    <>
      <Attestations employer={currentEmployer} warnings={warnings} />
      {showSelfSign && <SelfSignAttestation />}
      {showOnScreenAttestation && dmeOrder.doctor && (
        <SignOnScreenAttestation
          extraAttestationRequested={extraAttestationRequested}
          isExtraAttestationChecked={isExtraAttestationChecked}
          onChange={(event) => setExtraAttestationChecked(event.target.checked)}
          doctor={dmeOrder.doctor}
        />
      )}
    </>
  )
}

export default AttestationsSection
