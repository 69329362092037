import React from "react"
import { dmeOrderReorderUrl } from "applications/Workflow/urls"
import SingleButtonForm from "components/form/SingleButtonForm"

type Props = {
  dmeOrderId: string
}
const ReorderForm = ({ dmeOrderId }: Props) => {
  return (
    <SingleButtonForm
      action={dmeOrderReorderUrl(dmeOrderId)}
      className="btn btn-primary btn-block"
      buttonText="Reorder"
    />
  )
}

export default ReorderForm
