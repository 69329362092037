import React from "react"

interface Props {
  onClick(): void
}

function ClearAllLink({ onClick }: Props) {
  return (
    <>
      <a
        role="button"
        className="link link-understated link-sm nowrap canopy-mis-2x canopy-mbs-2x"
        onClick={onClick}
      >
        <small> Clear All Filters </small>
      </a>
    </>
  )
}

export default ClearAllLink
