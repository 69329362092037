import React, { useRef, useState } from "react"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { CanopyDialogModal } from "@parachutehealth/canopy-dialog-modal"
import ReadOnlyQuestionList from "../../../../../../../../../ReadOnlyQuestionList"
import { QuestionAndAnswers } from "../../../../../../../../../../sharedTypes"

interface Props {
  title: string
  questionsAndAnswers: QuestionAndAnswers[] | undefined
}

const ExternalQuestionnaireModalButton = (props: Props) => {
  const [open, setOpen] = useState(false)
  const activatorRef = useRef<HTMLButtonElement>(null)
  const openModal = () => setOpen(true)
  const closeModal = () => setOpen(false)

  return (
    <>
      {/*TODO: Reliant on canopy xsmall button being made
        update the below size to small and remove custom styling
      */}
      {props.questionsAndAnswers && (
        <CanopyButton
          size="small"
          style={{ minHeight: "24px", maxHeight: "24px" }}
          ref={activatorRef}
          onClick={openModal}
          className="canopy-mr-4x"
        >
          View
        </CanopyButton>
      )}
      <CanopyDialogModal
        activatorElementRef={activatorRef}
        header={props.title}
        headerOverline="Read Only"
        onClose={closeModal}
        open={open}
        size="medium"
        primaryFooterButton={
          <CanopyButton onClick={closeModal}>Close</CanopyButton>
        }
      >
        <ReadOnlyQuestionList questions={props.questionsAndAnswers} />
      </CanopyDialogModal>
    </>
  )
}

export default ExternalQuestionnaireModalButton
