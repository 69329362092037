// @ts-strict-ignore
import React from "react"
import DoctorSuggestion from "./Select/DoctorSuggestion"
import { DmeOrder, Doctor } from "sharedTypes"
import { cannotSignForMedicareOrders } from "utilities/signature"
import { newNpiDoctorRequestUrl } from "applications/Workflow/urls"
import { navigate } from "utilities/navigation"
import { Form, Select } from "components/form"
import Suggestion from "components/form/Select/Suggestion"
import TypeMoreCharactersSuggestion from "components/form/Select/Suggestion/TypeMoreCharacters"

type Props = {
  dmeOrder: DmeOrder
  doctorSearch({ value }): Promise<Doctor[]>
  selectDoctor(id: string): Promise<void>
  suggestedDoctors: Doctor[]
}

const doctorName = (doctor) => {
  return `${doctor.firstName} ${doctor.lastName} - ${doctor.npi}`
}

const getNewDoctorUrl = (name) => {
  const keywords = name.split(/\s+/)
  const keyword = keywords[0] || ""
  return `${newNpiDoctorRequestUrl()}?search_keyword=${keyword}`
}

const ClinicianSelect = ({
  dmeOrder,
  doctorSearch,
  selectDoctor,
  suggestedDoctors,
}: Props) => {
  const hasSuggestedDoctors = suggestedDoctors.length > 0
  const actualMinLength = 3
  const showingForSuggestedDoctors = (value) =>
    !value || value.length < actualMinLength
  const name = "clinician-select"
  const initialValues = {
    [name]: dmeOrder.doctor ? dmeOrder.doctor.doctorId : "",
  }
  const convertToOption = (doctor: Doctor) => ({
    value: doctor.doctorId,
    label: doctorName(doctor),
    ...doctor,
  })
  const initialOptions = dmeOrder.doctor
    ? [convertToOption(dmeOrder.doctor)]
    : []
  return (
    <div className="wide-fixed-row">
      <Form initialValues={initialValues}>
        <Select
          name={name}
          autoFocus={!dmeOrder.doctor && hasSuggestedDoctors}
          label="Who will be signing the order?"
          options={initialOptions}
          placeholder="Search by clinician name or NPI..."
          minLength={0}
          isSearchable
          isClearable
          fetchOptions={(value) => {
            if (showingForSuggestedDoctors(value)) {
              return new Promise((resolve) =>
                resolve([
                  {
                    label: "Suggested for you",
                    options: suggestedDoctors.map(convertToOption),
                  },
                ])
              )
            }
            return doctorSearch({ value }).then((res) =>
              res.map(convertToOption)
            )
          }}
          onChange={(value, suggestion) => {
            if (suggestion && suggestion.isOther) {
              return navigate(getNewDoctorUrl(suggestion.value))
            }
            if (
              suggestion &&
              !cannotSignForMedicareOrders(suggestion, dmeOrder)
            ) {
              selectDoctor(suggestion.doctorId)
            } else {
              selectDoctor(null)
            }
          }}
          renderOption={(doctor, { query, isFocused }) => (
            <DoctorSuggestion
              doctor={doctor}
              dmeOrder={dmeOrder}
              query={query}
              isHighlighted={isFocused}
            />
          )}
          openMenuOnFocus
          setValueOnSelect={(doctor) =>
            (doctor.isOther && doctor.value.length >= actualMinLength) ||
            (!doctor.isOther && !cannotSignForMedicareOrders(doctor, dmeOrder))
          }
          renderOtherOption={(option, { query }) => {
            if (query) {
              if (showingForSuggestedDoctors(query)) {
                return (
                  <TypeMoreCharactersSuggestion minlength={actualMinLength} />
                )
              }
              return (
                <Suggestion>
                  <a className="link">+ Create a New Clinician</a>
                </Suggestion>
              )
            }
          }}
        />
      </Form>
    </div>
  )
}

export default ClinicianSelect
